import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import news1 from "../../assets/images/secciones/news1.jpg";

export default function Innovaciones() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Row className="m-0 p-0 my-5">
      <Col xs={12} lg={{ span: 8, offset: 2 }}>
        <Row>
          <Col xs={12} style={{ color: "darkgray" }}>
            Innovaciones
          </Col>
          <Col xs={12}>
            <h3>
              Con Mobilize V2G, el futuro Renault 5 eléctrico se convierte en
              una fuente de energía
            </h3>
          </Col>
          <Col
            xs={1}
            style={{ borderBottom: "3px solid #efdf00", marginTop: "10px" }}
          ></Col>
        </Row>
        <Row>
          <Col xs={12} className="my-3">
            <img src={news1} alt="news1" width="100%" />
          </Col>
        </Row>
      </Col>
      <Row className="m-0 p-0" style={{ fontSize: "20px" }}>
        <Col xs={12} lg={{ span: 8, offset: 2 }}>
          <p>
            <b>
              Renault está dando un paso importante hacia la movilidad
              sostenible con el servicio Mobilize V2G (Vehicle-to-Grid), que se
              inaugurará en el futuro Renault 5 eléctrico. Esta revolucionaria
              característica es posible gracias al nuevo cargador bidireccional
              integrado de Renault.
            </b>
          </p>
          <br />
          <p>
            <b>¿Qué es la tecnología V2G? </b>
            <br />
            La tecnología V2G (Vehicle-to-Grid) permite la carga bidireccional,
            lo que significa que la electricidad fluye en ambas direcciones, de
            la red al vehículo y del vehículo a la red.
          </p>
          <br />
          <p>
            <b>¿Cuál es la ventaja del cargador bidireccional? </b>
            <br />
            Esta nueva tecnología innovadora permite enviar electricidad de
            vuelta a la terminal. El vehículo eléctrico se convierte así en una
            fuente de energía para el hogar y para la red eléctrica.
          </p>
          <br />
          <p>
            <b>Una nueva función: V2L </b>
            <br />
            Gracias al cargador bidireccional, el futuro Renault 5 eléctrico
            podrá alimentar los dispositivos electrónicos de nuestra vida
            cotidiana, como una aspiradora o una barbacoa. Esto es posible
            gracias a un adaptador desarrollado por Renault, capaz de
            proporcionar energía equivalente a la de una toma de corriente de
            220 voltios.
          </p>
          <br />
          <p>
            <b>Carga económica </b>
            <br />
            Gracias al cargador bidireccional del Renault 5, combinado con la
            estación de carga bidireccional Mobilize Powerbox y el servicio
            Mobilize V2G, los propietarios del futuro Renault 5 eléctrico podrán
            ahorrar en sus recargas y reducir su factura general de electricidad
            revendiendo electricidad en el mercado energético. De hecho, cuando
            el precio de la electricidad es alto y en la red cuando se solicita
            mucho. La carga, por su parte, se realiza cuando la energía es
            abundante en la red y, por lo tanto, más barata, el servicio puede
            incluso interrumpir la carga durante los picos de consumo.
          </p>
          <br />
          <p>
            <b>Energía libre de carbono </b>
            <br />
            Además, al facilitar la integración de las energías renovables en el
            mix energético, el contrato eléctrico de Mobilize recargará la
            batería del coche eléctrico con electricidad neutra en carbono. Así,
            los usuarios del servicio Mobilize V2G participarán activamente en
            la transición energética.
          </p>
          <br />
          <p>
            <b>Movilidad más asequible </b>
            <br />
            En última instancia, Mobilize V2G garantizará una movilidad más
            barata, como explica Corinne Frasson, Directora de Servicios de
            Energía de Mobilize: "Gracias a Mobilize V2G, el automóvil se
            convierte en una reserva de energía. Todo lo que el conductor tiene
            que hacer es conectar regularmente su vehículo a su Powerbox para
            optimizar su factura de electricidad y descarbonizar su movilidad.
            El costo de la carga se reducirá a la mitad para un cliente que
            viaja 12,000 km por año. De esta manera, Mobilize promueve una
            movilidad más sostenible y asequible".
          </p>
          <br />
          <p>
            <b>
              El servicio Mobilize V2G estará disponible en Francia a partir del
              lanzamiento del futuro Renault 5 eléctrico en 2024.
            </b>
            <br />
            Aunque forma parte del lanzamiento del Renault 5, esta iniciativa se
            extenderá a todos los futuros vehículos eléctricos de Renault.
          </p>
        </Col>
      </Row>
    </Row>
  );
}
