import React, { useEffect } from "react";

import useServicios from "./hooks/useServicios";

import { Row, Col /* , Image */ } from "react-bootstrap";

export default function Servicios() {
  const { getModelos } = useServicios();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/*  <Row className="m-0 mt-5 mb-3 p-0 justify-content-center">
        <Col className="text-center p-2" xs={12} md={{ span: 4 }}>
          <Image
            style={{ width: "100%", borderRadius: "5px" }}
            src="https://store.clama.com.ar/img/inform.jpg"
          ></Image>
        </Col>
      </Row> */}
      <Row className="m-0 p-0">
        <Col xs={12} md={{ span: 8, offset: 1 }} className="mt-5">
          <h3 style={{ fontFamily: "Renault Bold" }}>
            Selecciona el modelo y motor de tu vehículo
          </h3>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <Row className="m-0 p-0">{getModelos()}</Row>
        </Col>
      </Row>
    </>
  );
}
