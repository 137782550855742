export const miniaturas = {
  automoviles: [
    {
      nombre: "duster",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/duster.png",
    },
    {
      nombre: "kangoo",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/kangoo.png",
    },
    {
      nombre: "kardian",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/kardian.png",
    },
    {
      nombre: "koleos",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/koleos.png",
    },
    {
      nombre: "logan",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/logan.png",
    },
    {
      nombre: "sandero",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/sandero.png",
    },
    {
      nombre: "stepway",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/stepway.png",
    },
    {
      nombre: "megane",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/megane.png",
    },
    {
      nombre: "kwid",
      img: "https://store.clama.com.ar/v2/img/miniaturas/automoviles/kwid.png",
    },
  ],
  utilitarios: [
    {
      nombre: "kangooze",
      img: "https://store.clama.com.ar/v2/img/miniaturas/utilitarios/kangooze.png",
    },
    {
      nombre: "master",
      img: "https://store.clama.com.ar/v2/img/miniaturas/utilitarios/master.png",
    },
    {
      nombre: "oroch",
      img: "https://store.clama.com.ar/v2/img/miniaturas/utilitarios/oroch.png",
    },
    {
      nombre: "alaskan",
      img: "https://store.clama.com.ar/v2/img/miniaturas/utilitarios/alaskan.png",
    },
  ],
  pickup: [
    {
      nombre: "oroch pick-up",
      img: "https://store.clama.com.ar/v2/img/miniaturas/pickup/orochpu.png",
    },
  ],
};

export const modelos = {
  koleos: {
    nombre: "koleos",
    banner: "https://store.clama.com.ar/v2/img/koleos/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/koleos/koleos.pdf",
    modelo: "https://store.clama.com.ar/v2/img/koleos/modelo.png",
    slogan: "conquistalo todo",
    preFix: "diseño",
    header: "GRAN ESTILO, ALTA GAMA",
    subHeader:
      "Un imponente estilo y una refinada elegancia se combinan para ampliar tus horizontes. La nueva Renault KOLEOS es una SUV que inspira respeto. Es amplia, robusta, con grandes llantas y gran distancia entre ejes. Sin importar la ruta o aventura que quieras emprender con la Renault KOLEOS podés conquistarlo todo.",
    foto1: "https://store.clama.com.ar/v2/img/koleos/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/koleos/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/koleos/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/koleos/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "TECNOLOGÍA INTUITIVA PARA CONQUISTARLO TODO",
    subHeaderEquipo:
      "Al momento que te subís a la Renault KOLEOS vas a sentir placer de inmediato porque su tecnología te abre la puerta a nuevas experiencias.",
    featureImg1: "https://store.clama.com.ar/v2/img/koleos/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/koleos/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/koleos/feature3.jpg",
    featureHeader1: "Pantalla multimedia de 8,7”",
    featureHeader2: "Easy park assist",
    featureHeader3: "Todas las expresiones de confort",
    featureDescription1:
      "Descubrí mas allá y explora el universo intuitivo de la pantalla táctil de 8”. Su novedosa tecnología incluye un sistema de audio premium que te acompaña en todos tus viajes. ",
    featureDescription2:
      "Estacionar tu KOLEOS nunca fue tan fácil. Con el asistente de estacionamiento, podrás estacionar hasta en los espacios más estrechos.",
    featureDescription3:
      "La nueva Renault KOLEOS es ambiciosa como vos, y toma en cuenta todos los aspectos para tu comodidad y la de los pasajeros, ofreciendo un amplio habitáculo para su confort. El asiento te envuelve mientras que el techo panorámico muestra una perspectiva única. El bienestar está en todos lados a bordo de la nueva KOLEOS.",
  },
  logan: {
    nombre: "logan",
    banner: "https://store.clama.com.ar/v2/img/logan/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/logan/logan.pdf",
    modelo: "https://store.clama.com.ar/v2/img/logan/modelo.png",
    slogan: "sentite cómodo con tu momento",
    preFix: "diseño",
    header: "Un sedán para el momento en el que estás.",
    subHeader:
      "Dejate sorprender. Rediseñado con una nueva identidad visual, el Renault LOGAN cuenta con las cualidades que caracterizan al vehículo con el diseño francés.",
    foto1: "https://store.clama.com.ar/v2/img/logan/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/logan/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/logan/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/logan/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "Descubrí todo lo que tiene Renault LOGAN.",
    subHeaderEquipo: "Renault LOGAN tiene muchas cosas nuevas para ofrecerte!",
    featureImg1: "https://store.clama.com.ar/v2/img/logan/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/logan/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/logan/feature3.jpg",
    featureHeader1: "Disfrutá de un viaje placentero",
    featureHeader2: "Mantenete conectado",
    featureHeader3: "Tu seguridad garantizada",
    featureDescription1:
      "¿Buscás confort? ¡Con Renault LOGAN, viajás con la mejor comodidad posible! Un generoso espacio y prácticas áreas de almacenamiento.",
    featureDescription2:
      "¡Simplificá tu vida! Renault LOGAN está equipado con tecnología para un mayor confort y comodidad a bordo.",
    featureDescription3:
      "Porque la seguridad a bordo es fundamental, Renault LOGAN ha sido diseñado para ofrecer la máxima tranquilidad al conducir.",
  },
  kangoo: {
    nombre: "kangoo",
    banner: "https://store.clama.com.ar/v2/img/kangoo/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/kangoo/kangoo.pdf",
    modelo: "https://store.clama.com.ar/v2/img/kangoo/modelo.png",
    slogan: "recargado de proyectos",
    preFix: "Diseño, más tecnología",
    header: "VIAJEMOS JUNTOS",
    subHeader:
      "Renault Kangoo stepway, pensado para viajar acompañado y compartir todo tipo de aventuras. En familia o con amigos, al campo o a la playa, Kangoo stepway está diseñado para que cada travesía sea única, no sólo en fines de semana o vacaciones. Con amplio espacio, también es un vehículo ideal para moverse cómodamente por la ciudad. Para ir al trabajo, llevar los chicos al colegio, hacer las compras en el supermercado o para disfrutar de salidas divertidas. Todos serán bienvenidos, todos viajarán cómodos en Renault Kangoo stepway.",
    foto1: "https://store.clama.com.ar/v2/img/kangoo/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/kangoo/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/kangoo/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/kangoo/foto4.jpg",
    preFixEquipo: "Performance",
    headerEquipo: "Motorización",
    subHeaderEquipo: (
      <span>
        <span>
          <b>Motor Nafta 1.6 L</b>, consumo (ciclo combinado ruta/ciudad) 7.6 L
          cada 100 km, capacidad del tanque de combustible 50 L, autonomía 658
          km, potencia 114 CV a 5500 r.p.m., torque máximo 156 Nm a 4000 r.p.m.
        </span>{" "}
        <br />{" "}
        <span>
          <b>Motor Diesel 1.5 L</b>, consumo (ciclo combinado ruta/ciudad) 4.9 L
          cada 100 km, capacidad del tanque de combustible 50 L, autonomía 1000
          km, potencia 89 CV a 4000 r.p.m.,torque máximo 220 Nm a 1750 r.p.m.
        </span>
      </span>
    ),
    featureImg1: "https://store.clama.com.ar/v2/img/kangoo/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/kangoo/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/kangoo/feature3.jpg",
    featureHeader1: "Media Evolution®",
    featureHeader2: "Cámara de estacionamiento trasera",
    featureHeader3: "Limitador y control de velocidad crucero",
    featureDescription1:
      "Disfrutá del sistema Media Evolution® y su pantalla táctil de 7 pulgadas, Bluetooth y sistema de teléfono manos libres con controles al volante.",
    featureDescription2:
      "Para maniobrar con confianza y tranquilidad, Kangoo stepway cuenta con sensores de estacionamiento traseros y cámara de visión trasera con línea de trayectoria, mostrando así cuál será el recorrido del camino.",
    featureDescription3:
      "El control de velocidad crucero permite al conductor definir una velocidad de circulación y mantener esa velocidad sin la necesidad de presionar el pedal del acelerador. También, podés elegir el limitador en velocidad, definiendo la velocidad máxima con los comandos al volante.",
  },
  sandero: {
    nombre: "sandero",
    banner: "https://store.clama.com.ar/v2/img/sandero/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/sandero/sandero.pdf",
    modelo: "https://store.clama.com.ar/v2/img/sandero/modelo.png",
    slogan: "auto de ciudad con estilo.",
    preFix: "diseño",
    header: "SOS URBANO, SOS ALGUIEN CON CARÁCTER.",
    subHeader:
      "Renault SANDERO es el auto para todo lo que sos y entre todas tus versiones, sos alguien que disfruta del diseño.",
    foto1: "https://store.clama.com.ar/v2/img/sandero/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/sandero/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/sandero/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/sandero/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "Equipado para todo lo que sos.",
    subHeaderEquipo:
      "Cámara de estacionamiento trasera, control crucero y limitador de velocidad, sistema Media Evolution® a bordo.",
    featureImg1: "https://store.clama.com.ar/v2/img/sandero/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/sandero/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/sandero/feature3.jpg",
    featureHeader1: "Cámara de estacionamiento trasera",
    featureHeader2: "Experimentá el confort total",
    featureHeader3: "ESP y HSA",
    featureDescription1:
      "Junto con los sensores traseros, las maniobras se realizan con facilidad y tendrás mayor control a la hora de estacionar.",
    featureDescription2:
      "Dale la bienvenida a tus pasajeros en tu Renault SANDERO. El más espacioso de la categoría, optimizado con sus nuevos asientos ergonómicos para brindarte más comodidad.",
    featureDescription3:
      "El Control de Estabilidad (ESP) recupera la trayectoria en maniobras bruscas, controlando la tracción y la frenada de cada rueda. Sistema de ayuda en pendiente (HSA) impide que el auto retroceda al momento de arrancar en un terreno inclinado.",
  },
  stepway: {
    nombre: "stepway",
    banner: "https://store.clama.com.ar/v2/img/stepway/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/stepway/stepway.pdf",
    modelo: "https://store.clama.com.ar/v2/img/stepway/modelo.png",
    slogan: "aventurero por fuera, urbano de corazón",
    preFix: "diseño",
    header:
      "UN VEHÍCULO CON ACTITUD PARA DISFRUTAR LA AVENTURA DE VIVIR EN LA CIUDAD.",
    subHeader:
      "¿Buscás un auto con personalidad? Este es el auto perfecto para vos.",
    foto1: "https://store.clama.com.ar/v2/img/stepway/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/stepway/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/stepway/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/stepway/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "Confort y seguridad",
    subHeaderEquipo:
      "Renault STEPWAY está hecho para llevarte por los caminos que vos querés y con la tranquilidad de tener todo bajo control.",
    featureImg1: "https://store.clama.com.ar/v2/img/stepway/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/stepway/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/stepway/feature3.jpg",
    featureHeader1: "Nuevo Motor",
    featureHeader2: "Seguridad Pasiva",
    featureHeader3: "Seguridad Activa",
    featureDescription1:
      "El motor HR16 1.6 L, 16 válvulas con 115 cv y 156 Nm ofrece una respuesta ágil en ciudad con una excelente performance en consumo. Esta nueva motorización se adapta perfectamente ante las exigencias de potencia, tanto en circuitos urbanos como en ruta.",
    featureDescription2:
      "Cuatro Airbags de serie, Alarma Perimétrica. Estructura reforzada. Cinturones de seguridad de 3 puntos y apoyacabezas regulables en altura.",
    featureDescription3:
      "Control Electrónico de Estabilidad (ESP), que recupera la trayectoria en maniobras bruscas, controlando la tracción y la frenada de cada rueda. Frenos ABS. Asistencia Frenado de Urgencia (AFU). Asistencia de arranque en pendiente (HSA), impide que el auto retroceda al momento de arrancar en un terreno inclinado.",
  },
  duster: {
    nombre: "duster",
    banner: "https://store.clama.com.ar/v2/img/duster/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/duster/duster.pdf",
    modelo: "https://store.clama.com.ar/v2/img/duster/modelo.png",
    slogan: "La vida no tiene un solo camino.",
    preFix: "diseño",
    header: "LISTO PARA TU PRÓXIMA AVENTURA",
    subHeader:
      "Hacia dónde te dirigís depende 100% de vos, por eso llegó RENAULT DUSTER para que puedas tomar el rumbo que quieras. Un SUV diseñado para disfrutar de la vida off road, nuevas experiencias y recorrer todos los rincones de nuestro país de una forma atrevida, conectada y con personalidad.",
    foto1: "https://store.clama.com.ar/v2/img/duster/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/duster/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/duster/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/duster/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "ABRÍ EL PASO",
    subHeaderEquipo:
      "El RENAULT DUSTER cuenta con todo equipamiento para que encuentres tu propio camino.",
    featureImg1: "https://store.clama.com.ar/v2/img/duster/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/duster/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/duster/feature3.jpg",
    featureHeader1: "Motor 1.3L TCe Turbo y caja CVT X-Tronic 8",
    featureHeader2: "Exterior e interior renovados",
    featureHeader3: "Nueva tecnología y conectividad",
    featureDescription1:
      "Este motor de última generación combina  performance y eficiencia, que entrega hasta 155cv y un torque de 250Nm a 1800 r.p.m. inyección electrónica y transmisión de 6 marchas manual o CVT X.Tronic 8 velocidades.",
    featureDescription2:
      "Con tan solo una mirada te das cuenta de su robustez. Nuevas líneas mas agresivas, parrilla cromada, faros delanteros LED C-Shape potencian su carácter aventurero, practico y robusto. Además su diseño interior completamente renovado te garantiza la experiencia más confortable.",
    featureDescription3:
      "Como en el cine, la tecnología se apodera de todo. Nuevo sistema multimedia Easy Link con pantalla de 8” compatible con Android Auto y Apple CarPlay de manera inálambrica para que puedas disfrutar de todas tus aplicaciones sin necesidad de enchufar tu celular.",
  },
  kangooze: {
    nombre: "kangooze",
    banner: "https://store.clama.com.ar/v2/img/kangooze/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/kangooze/kangooze.pdf",
    modelo: "https://store.clama.com.ar/v2/img/kangooze/modelo.png",
    slogan: "para cambiar todo, empezamos de Zero",
    preFix: "estilo asertivo",
    header: "DESCUBRÍ EL NUEVO RENAULT KANGOO Z.E.",
    subHeader:
      "Creado a la medida de las necesidades de tu negocio, ¡el nuevo KANGOO Z.E. está diseñado para profesionales!",
    foto1: "https://store.clama.com.ar/v2/img/kangooze/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/kangooze/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/kangooze/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/kangooze/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo:
      "Creado a la medida de las necesidades de tu negocio, ¡el nuevo KANGOO Z.E. está diseñado para profesionales!",
    subHeaderEquipo:
      "Una versión para adaptarse a cada necesidad. Renault te ofrece niveles de acabados para satisfacer todas tus expectativas.",
    featureImg1: "https://store.clama.com.ar/v2/img/kangooze/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/kangooze/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/kangooze/feature3.jpg",
    featureHeader1: "MOTOR 100% ELÉCTRICO",
    featureHeader2: "TRANSMISIÓN Y FRENOS",
    featureHeader3: "BATERÍA",
    featureDescription1:
      "Potencia: 60 CV Vel. Máx: 130 km/h Origen: Cleón, Francia (Renault)",
    featureDescription2:
      "Caja automática de una marcha y sistema de frenos regenerativos.",
    featureDescription3:
      "Batería 33 kW/h (ION Litio) Autonomía: 270Kms. NEDC La vida útil de la batería es de al menos 8 años, luego de los cuáles la misma deberá ser reciclada; para esto, el cliente deberá acercarse a un concesionario oficial Z.E. La batería y el motor cuentan con una garantía de 5 años.",
  },
  master: {
    nombre: "master",
    banner: "https://store.clama.com.ar/v2/img/master/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/master/master.pdf",
    modelo: "https://store.clama.com.ar/v2/img/master/modelo.png",
    slogan: "remasterizada para grandes cosas",
    preFix: "MÁS CONFORT",
    header: "PRACTICIDAD Y EFICIENCIA",
    subHeader:
      "Para empresas con voluntad de crecer.  Posee sistemas avanzados de asistencia al conductor y una capacidad de carga de 8 m³.  La Renault Master se adapta a todo lo que tu empresa necesite llevar. ",
    foto1: "https://store.clama.com.ar/v2/img/master/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/master/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/master/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/master/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "DISEÑO INTERIOR",
    subHeaderEquipo:
      "La Renault Master reafirma su personalidad fuerte y moderna con un nuevo diseño exterior. Nuevo paragolpes delantero, nueva parrilla y nuevas luces de circulación diurna (DRL) en forma de “C” con tecnología LED. Se adapta más a las necesidades profesionales, lo que convierte a Master en el vehículo aliado, perfecto para tu negocio.",
    featureImg1: "https://store.clama.com.ar/v2/img/master/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/master/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/master/feature3.jpg",
    featureHeader1:
      "control de estabilidad electrónico (ESP) y control de carga adaptativo (LAC)",
    featureHeader2: "asistente de arranque en pendiente (HSA)",
    featureHeader3: "mitigación de vuelco (ROM)",
    featureDescription1:
      "El ESP permite corregir la trayectoria del vehículo para evitar deslizamientos.  El LAC optimiza la eficacia de frenado, la tracción y la estabilidad al detectar la masa de la carga y el centro de gravedad del vehículo.",
    featureDescription2:
      "Junto con el ESP,  mantiene la presión de los frenos durante dos segundos para que tengas tiempo de acelerar.  El sistema se activa al detectar una pendiente superior al 3%.",
    featureDescription3:
      "Controla la estabilidad del vehículo cuando el riesgo de vuelco es alto.  Estabilidad y seguridad en cualquier circunstancia.",
  },
  oroch: {
    nombre: "oroch",
    banner: "https://store.clama.com.ar/v2/img/oroch/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/oroch/oroch.pdf",
    modelo: "https://store.clama.com.ar/v2/img/oroch/modelo.png",
    slogan: "Para el trabajo y la vida",
    preFix: "versatilidad",
    header: "Equipada con fuerza y ​​rendimiento.",
    subHeader:
      "Renault Oroch te ofrece lo mejor de dos mundos, disfrutá de trayectos largos en familia o de actividades laborales, con capacidades excepcionales de manejo y seguridad. Viene equipada con un motor turbo 1.3 TCe de 163 cv, sistema multimedia con pantalla de 8” compatible con Apple CarPlay® y Android Auto®, suspensión Multilink, con capacidad de carga de hasta 650 kg y espacio para 5 personas. ¡Descubrí la pick-up única que se adapta a tu estilo de vida!",
    foto1: "https://store.clama.com.ar/v2/img/oroch/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/oroch/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/oroch/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/oroch/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "ROBUSTEZ Y CARGA",
    subHeaderEquipo:
      "La caja tiene una capacidad de hasta 650kg de carga: se adapta a cualquier necesidad de tu trabajo o de tu viaje de fin de semana. Las barras de techo funcionales soportan hasta 80 kg y la barra de San Antonio añaden más robustez al diseño, además de facilitar el transporte. El paragolpes y la parrilla delantera le dan a la pick-up un toque aún más imponente. En el campo, en la playa o en la ciudad, las características de Renault Oroch son impresionantes.",
    featureImg1: "https://store.clama.com.ar/v2/img/oroch/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/oroch/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/oroch/feature3.jpg",
    featureHeader1: "Control de Estabilidad y Tracción (ESP y TCS)",
    featureHeader2: "Asistente de arranque en pendiente (HSA)",
    featureHeader3: "Sistema de rollover ( ROM)",
    featureDescription1:
      "Más seguridad y estabilidad, incluso en situaciones de baja adherencia.",
    featureDescription2:
      "Facilita automáticamente la salida en pendientes pronunciadas.",
    featureDescription3:
      "Reduce automáticamente la velocidad del vehículo en caso de emergencia, evitando accidentes.",
  },
  alaskan: {
    nombre: "alaskan",
    banner: "https://store.clama.com.ar/v2/img/alaskan/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/alaskan/alaskan.pdf",
    modelo: "https://store.clama.com.ar/v2/img/alaskan/modelo.png",
    slogan: "hecha para los que hacen",
    preFix: "Diseño imponente",
    header: "La heredera de una historia de excelencia y superación",
    subHeader:
      "Nuestra Fábrica Santa Isabel en Córdoba, cumple 68 años ininterrumpidos de trabajo y lo festejamos como mejor sabemos hacer: aplicando eficiencia, alta ingeniería e innovación al desarrollo de la nueva pick-up argentina; Renault Alaskan. Una aliada potente, robusta y confortable, hecha en nuestro país, y equipada para acompañarte sin importar terrenos, obstáculos o carga.",
    foto1: "https://store.clama.com.ar/v2/img/alaskan/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/alaskan/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/alaskan/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/alaskan/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo:
      "Hicimos una pick-up hecha con la fuerza de los que hacen. Potente y versátil para todo lo que tenés que hacer.",
    subHeaderEquipo:
      "En Renault siempre seguimos adelante, con nuevos proyectos y nuevas ganas.",
    featureImg1: "https://store.clama.com.ar/v2/img/alaskan/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/alaskan/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/alaskan/feature3.jpg",
    featureHeader1: "Diseño imponente",
    featureHeader2: "Tecnología inteligente e intuitiva",
    featureHeader3: "Robusta y confortable",
    featureDescription1:
      "Las primeras impresiones son importantes. Por eso su diseño muestra su carácter fuerte desde el primer minuto. Viví la vida Renault Alaskan: potente, ágil y versátil – con una pick-up que se adapta a vos.",
    featureDescription2:
      "Renault Alaskan es más seguridad para vos. Con su equipamiento inteligente, cada viaje es algo único. Subite y descubrí toda su tecnología. ",
    featureDescription3:
      "Vayas donde vayas, andá con todo el confort de tu Renault Alaskan. Combinamos un chasis escalonado de acero reforzado de alta resistencia a la torsión, con su suspensión trasera multilink para una mayor maniobrabilidad. Más confort con o sin carga.",
  },
  "oroch pick-up": {
    nombre: "oroch pick-up",
    banner: "https://store.clama.com.ar/v2/img/orochpu/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/orochpu/orochpu.pdf",
    modelo: "https://store.clama.com.ar/v2/img/orochpu/modelo.png",
    slogan: "Para el trabajo y la vida",
    preFix: "versatilidad",
    header: "Equipada con fuerza y ​​rendimiento.",
    subHeader:
      "Renault Oroch te ofrece lo mejor de dos mundos, disfrutá de trayectos largos en familia o de actividades laborales, con capacidades excepcionales de manejo y seguridad. Viene equipada con un motor turbo 1.3 TCe de 163 cv, sistema multimedia con pantalla de 8” compatible con Apple CarPlay® y Android Auto®, suspensión Multilink, con capacidad de carga de hasta 650 kg y espacio para 5 personas. ¡Descubrí la pick-up única que se adapta a tu estilo de vida!",
    foto1: "https://store.clama.com.ar/v2/img/orochpu/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/orochpu/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/orochpu/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/orochpu/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "ROBUSTEZ Y CARGA",
    subHeaderEquipo:
      "La caja tiene una capacidad de hasta 650kg de carga: se adapta a cualquier necesidad de tu trabajo o de tu viaje de fin de semana. Las barras de techo funcionales soportan hasta 80 kg y la barra de San Antonio añaden más robustez al diseño, además de facilitar el transporte. El paragolpes y la parrilla delantera le dan a la pick-up un toque aún más imponente. En el campo, en la playa o en la ciudad, las características de Renault Oroch son impresionantes.",
    featureImg1: "https://store.clama.com.ar/v2/img/orochpu/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/orochpu/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/orochpu/feature3.jpg",
    featureHeader1: "Control de Estabilidad y Tracción (ESP y TCS)",
    featureHeader2: "Asistente de arranque en pendiente (HSA)",
    featureHeader3: "Sistema de rollover ( ROM)",
    featureDescription1:
      "Más seguridad y estabilidad, incluso en situaciones de baja adherencia.",
    featureDescription2:
      "Facilita automáticamente la salida en pendientes pronunciadas.",
    featureDescription3:
      "Reduce automáticamente la velocidad del vehículo en caso de emergencia, evitando accidentes.",
  },
  kardian: {
    nombre: "kardian",
    banner: "https://store.clama.com.ar/v2/img/kardian/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/kardian/kardian.pdf",
    modelo: "https://store.clama.com.ar/v2/img/kardian/modelo.png",
    slogan: "el cambio que lo cambia todo",
    preFix: "diseño",
    header: "Cambio desde el interior",
    subHeader:
      "Disfrutá de un espacio acogedor diseñado tanto para el conductor como para los pasajeros, con materiales de primera calidad y acabados de alta gama. El habitáculo es amplio y cómodo, con asientos ergonómicos de diseño Renault que aseguran una experiencia de conducción placentera. Además, la consola central está equipada con e-shifter con caja de doble embrague, brindándote un control total en tu viaje. Preparate para dominar el camino con un placer de conducción inigualable.",
    foto1: "https://store.clama.com.ar/v2/img/kardian/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/kardian/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/kardian/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/kardian/foto4.jpg",
    preFixEquipo: "diseño",
    headerEquipo: "Cambio en el exterior",
    subHeaderEquipo:
      "Un SUV distintivo, el nuevo diseño del Renault Kardian marca un antes y un después con sus líneas ágiles, modernas y dinámicas. La versión Premiere Edition aporta un toque de originalidad y distinción con su vibrante color naranja energy y techo negro. En la parte delantera, el paragolpes robusto cuenta con una parrilla completamente nueva que presenta el nuevo emblema de Renault, enmarcada por diamantes geométricos negros que reflejan la luz. La iluminación de doble nivel utiliza luces LED.",
    featureImg1: "https://store.clama.com.ar/v2/img/kardian/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/kardian/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/kardian/feature3.jpg",
    featureHeader1: "Freno de estacionamiento electrónico",
    featureHeader2: "Motor 1.0 turbo con 220Nm de torque",
    featureHeader3: "Consola elevada y palanca electrónica",
    featureDescription1:
      "El freno de estacionamiento electrónico reemplaza al freno de mano tradicional. Es sencillo y práctico de operar, lo que facilita las maniobras. Su interruptor de perfil bajo está al alcance de la mano, directamente en la consola central elevada.",
    featureDescription2:
      "El Renault Kardian ofrece 120 CV con su motor turbo de 3 cilindros.",
    featureDescription3:
      "alta tecnología con un diseño exclusivo: la nueva consola elevada es ergonómica y amigable para el conductor, lo que permite utilizar el selector de marchas 'e-shifter' de manera intuitiva.",
  },
  niagara: {
    nombre: "niagara",
    banner: "https://store.clama.com.ar/v2/img/niagara/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/niagara/niagara.pdf",
    modelo: "https://store.clama.com.ar/v2/img/niagara/modelo.png",
    slogan: "Símbolo de la ofensiva internacional",
    preFix: "diseño",
    header: "Reflejando la gama renovada en mercados fuera de Europa",
    subHeader:
      "Con una personalidad distintiva que simboliza los valores de Renault, el Concepto RENAULT NIAGARA CONCEPT presenta líneas generosas y envolventes, así como detalles esculpidos y acabados en un vehículo que es a la vez robusto y contemporáneo. Donde la emoción y la tecnología se encuentran con el Arte Pixel. Inspiración y valores; fiabilidad y sólidas credenciales tecnológicas con su nueva firma de iluminación.",
    foto1: "https://store.clama.com.ar/v2/img/niagara/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/niagara/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/niagara/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/niagara/foto4.jpg",
    preFixEquipo: "diseño",
    headerEquipo: "Diseñado para condiciones extremas",
    subHeaderEquipo:
      "Desde su resistente chapón cubre-cárter hasta su suspensión de gran recorrido que brinda amortiguación incluso en rutas con baches, junto con amplios ángulos de despeje, RENAULT NIAGARA CONCEPT encarna todas las características físicas y técnicas de una camioneta pick-up fuerte, dinámica y deportiva.",
    featureImg1: "https://store.clama.com.ar/v2/img/niagara/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/niagara/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/niagara/feature3.jpg",
    featureHeader1: "EL FUTURO DE LA GAMA INTERNACIONAL",
    featureHeader2: "CONCEPTO CON UNA FUERTE PERSONALIDAD",
    featureHeader3: "TECNOLOGÍA E-TECH 4WD INNOVADORA",
    featureDescription1:
      "RENAULT NIAGARA encarna la modernidad de la futura gama internacional de Renault prevista para 2027.",
    featureDescription2: "Robusto y potente, sofisticado y rico en tecnología.",
    featureDescription3:
      "Avanzada tecnología mild hybrid en la parte delantera y motor eléctrico en la parte trasera.",
  },
  megane: {
    nombre: "megane",
    banner: "https://store.clama.com.ar/v2/img/megane/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/megane/megane.pdf",
    modelo: "https://store.clama.com.ar/v2/img/megane/modelo.png",
    slogan: "de caballos de fuerza a energía eléctrica",
    preFix: "diseño",
    header: "excelencia en diseño",
    subHeader:
      "Renault Megane E-Tech 100% eléctrico cuenta con un innovador diseño crossover deportivo. Su carrocería es aerodinámica, de líneas modernas, fluidas y sofisticadas. Las llantas de aleación Oston son de 18” y las luces, el DRL y los faros antiniebla son full LED, con luces de giro dinámicas. Además, su estructura exterior contempla detalles elegantes y originales como la carrocería bitono.",
    foto1: "https://store.clama.com.ar/v2/img/megane/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/megane/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/megane/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/megane/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "tecnología que protege",
    subHeaderEquipo:
      "El RENAULT K-ZE es un vehículo eléctrico accesible para todos. Con un diseño moderno y compacto, es ideal para la ciudad. Con una autonomía de 271 km, es perfecto para tus desplazamientos diarios. Además, su batería es compatible con cargadores rápidos y lentos, para que puedas recargarla en casa o en la ciudad. Descubre el placer de conducir un vehículo eléctrico con el RENAULT K-ZE.",
    featureImg1: "https://store.clama.com.ar/v2/img/megane/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/megane/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/megane/feature3.jpg",
    featureHeader1: "ergonómico y espacioso",
    featureHeader2: "máxima experiencia de conducción",
    featureHeader3: "diseño modular y almacenamiento",
    featureDescription1:
      "La plataforma exclusiva del Megane E-Tech 100% eléctrico tiene un suelo completamente plano que garantiza un amplio espacio interior en todo el habitáculo.",
    featureDescription2:
      "Tecnología Multi-Sense para elegir entre cuatro modos de manejo, panel de instrumentos de 12.3’ y sistema multimedia Open R-Link de 9.9’ con Android Auto® y Apple Car Play® inalámbricos.",
    featureDescription3:
      "Compartimento de almacenamiento en la consola central, 33 L de espacio de carga en el centro y laterales y 440 L en el baúl.",
  },
  kwid: {
    nombre: "kwid",
    banner: "https://store.clama.com.ar/v2/img/kwid/banner.jpg",
    catalogo: "https://store.clama.com.ar/v2/img/kwid/kwid.pdf",
    modelo: "https://store.clama.com.ar/v2/img/kwid/modelo.png",
    slogan: "la revolución eléctrica está en las calles",
    preFix: "diseño",
    header: "DISEÑADO PARA TU ESTILO DE VIDA",
    subHeader:
      "Renault Kwid E-Tech 100% eléctrico tiene la actitud y la robustez de un SUV compacto, además de tecnología E-Tech 100% eléctrica y una altura libre al suelo de 17 cm. Es un vehículo con un diseño innovador y líneas llamativas, además de ofrecer un completo paquete de seguridad.",
    foto1: "https://store.clama.com.ar/v2/img/kwid/foto1.jpg",
    foto2: "https://store.clama.com.ar/v2/img/kwid/foto2.jpg",
    foto3: "https://store.clama.com.ar/v2/img/kwid/foto3.jpg",
    foto4: "https://store.clama.com.ar/v2/img/kwid/foto4.jpg",
    preFixEquipo: "equipamiento",
    headerEquipo: "Ahorra energía, pero no tecnología",
    subHeaderEquipo:
      "El Renault Kwid E-Tech 100% eléctrico está equipado con varias tecnologías que mejoran tu experiencia de conducción y también reducen el consumo energético del auto.",
    featureImg1: "https://store.clama.com.ar/v2/img/kwid/feature1.jpg",
    featureImg2: "https://store.clama.com.ar/v2/img/kwid/feature2.jpg",
    featureImg3: "https://store.clama.com.ar/v2/img/kwid/feature3.jpg",
    featureHeader1: "6 airbags",
    featureHeader2: "Control electrónico de estabilidad (ESP)",
    featureHeader3: "Asistente de arranque en pendiente (HSA)",
    featureDescription1:
      "Para quienes conducen y quienes viajan: el Renault Kwid E-Tech 100% eléctrico ofrece 6 airbags paraproteger a todos los pasajeros.",
    featureDescription2:
      "Conducir es más seguro con el Control Electrónico de Estabilidad del Renault Kwid E-Tech 100%eléctrico, aumentando la estabilidad y seguridad del vehículo.",
    featureDescription3:
      "Renault Kwid E-Tech 100% eléctrico ofrece asistencia de arranque en pendiente, colaborando a tu manejo en ocasiones de caminos en pendiente.",
  },
};
