import React, { useEffect } from "react";

import { Row, Col, Spinner, Form, Button } from "react-bootstrap/";
import { capitalize } from "../../constants/constants";

import useRepuestos from "./hooks/useRepuestos";

import repuestosSeccion from "../../assets/images/secciones/repuestosSeccion.jpg";
import repuestosSeccionMobile from "../../assets/images/secciones/repuestosSeccionMobile.jpg";
export default function CatalogoRepuestos() {
  const {
    spinner,
    genericCardstoRender,
    buttonsToRender,
    handleChangeFilter,
    search,
    getPagination,
    seleccion,
    handleSearch,
  } = useRepuestos();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Row className="m-0 p-0">
        <Col xs={12} className="m-0 p-0">
          <p className="textoUsados text-left">Repuestos</p>
          <picture>
            <source media="(min-width:600px)" srcset={repuestosSeccion} />
            <img
              src={repuestosSeccionMobile}
              alt="accesorios"
              style={{ width: "100%" }}
            />
          </picture>
        </Col>
      </Row>
      <Row className="m-0 mt-3 mb-3 p-0 justify-content-center">
        {/*  {marcaSeleccionada === MARCAS.TOYOTA_CERTIFICADO ? ( //aca puede venir el banner de accesorios
          <Col className="text-left p-2" xs={12} md={{ span: 4 }}>
            <Image
              className="mb-4"
              style={{ width: "90%", borderRadius: "5px" }}
              src="https://store.lenken.com.ar/img/imgSitio/logos/usadosSele.jpg"
            ></Image>
          </Col>
        ) : (
          ""
        )} */}
        <Col
          className="text-center mb-1 d-flex justify-content-center align-items-center"
          lg={9}
          style={{
            backgroundColor: "#D9D9D9",
            fontSize: "2rem",
            color: "black",
            flexWrap: "wrap",
          }}
        >
          {buttonsToRender()}
        </Col>
        <Col
          className="text-left pl-4 mb-1"
          lg={9}
          style={{
            backgroundColor: "#000",
            fontSize: "2rem",
            color: "black",
          }}
        >
          <Row className="m-0 p-0" style={{ height: "40px" }}>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 4 }}
              className="text-center mt-1"
              style={{ color: "white" }}
            >
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  style={{ borderRadius: "0px" }}
                  placeholder="buscar"
                  value={search}
                  onChange={(e) => handleChangeFilter(e)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Button
              size="sm"
              className="mt-1"
              onClick={() => handleSearch()}
              variant="outline-light"
              style={{
                border: "1px solid white",
                fontWeight: "bold",
                fontFamily: "Renault Bold",
                borderRadius: "0px",
                color: "white",
                height: "30px",
              }}
            >
              buscar
            </Button>
          </Row>
        </Col>
        <Col
          className="text-left p-1 pl-4"
          lg={9}
          style={{
            backgroundColor: "darkgray",
            fontSize: "1.5rem",
            borderRadius: "5px",
            color: "black",
          }}
        >
          {seleccion && seleccion === "combos"
            ? "Ofertas"
            : "Repuestos " + capitalize(seleccion.toLowerCase())}
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5 justify-content-center text-left">
        {!spinner ? (
          genericCardstoRender()
        ) : (
          <>
            <Row className="mx-0" style={{ marginTop: "2%" }}>
              <Col xs={12} className="text-center">
                Cargando...
              </Col>
              <Col
                xs={12}
                className="text-center mx-0"
                style={{ marginTop: "10px" }}
              >
                <Spinner animation="grow" variant="secondary" />
              </Col>
            </Row>
          </>
        )}
      </Row>
      {getPagination()}
    </>
  );
}
