import { useEffect, useState } from "react";

import { DateTime } from "luxon";

export default function useSeleccionTurno({ product }) {
  const [horariosNoDisponibles, setHorariosNoDisponibles] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [day, setDay] = useState("");
  const [time, setTime] = useState("");
  const [show, setShow] = useState(false);
  const [rawDateTime, setRawDateTime] = useState("");

  const handleDateTime = (dateTime) => {
    setRawDateTime(dateTime);
    setDateTime(
      DateTime.fromJSDate(new Date(dateTime)).toFormat("dd/MM/yyyy HH:mm")
    );
    setDay(DateTime.fromJSDate(new Date(dateTime)).toFormat("dd/MM/yyyy"));
  };

  const handleTime = (time) => {
    time = DateTime.fromJSDate(new Date(time)).toFormat("HH:mm");

    let fixedTime = time.slice(3, 5);

    if (fixedTime === "30" || fixedTime === "00") {
      setTime(time);
    }
  };

  const handleShow = (value) => {
    setShow(value);
  };

  const local = "CC01";
  /*  product && product.sucursal === "P. Calderon de la Barca 3036"
      ? "CA30"
      : "LL33"; */

  let yearParam = rawDateTime && rawDateTime.getFullYear();
  let monthParam =
    rawDateTime && (rawDateTime.getMonth() + 1).toString().padStart(2, "0");
  let dayParam = rawDateTime && rawDateTime.getDate();

  useEffect(() => {
    var url = `https://dash.clama.com.ar/clamaapi/v2/public/turnosPosventa/turnos/Clama/${yearParam}/${monthParam}/${dayParam}/${local}`; //ver este punto a ver si estan funcionando los horarios

    if (dateTime !== "") {
      fetch(url)
        .then((data) => {
          //console.log(data);
          return data.json();
        })
        .then((horarios) => {
          console.log("🚀 ~ horarios:", horarios);
          setHorariosNoDisponibles(horarios);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTime]);

  return {
    horariosNoDisponibles,
    handleDateTime,
    day,
    time,
    handleShow,
    show,
    rawDateTime,
    handleTime,
  };
}
