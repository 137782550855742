import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row, Spinner } from "react-bootstrap";

import { useLocation, useHistory, Link } from "react-router-dom";
import useSeleccionServicio from "./hooks/useSeleccionServicio";

export default function SeleccionSucursal() {
  const { items, handleSeleccionSucursal } = useSeleccionServicio();
  const [spinner, setSpinner] = useState(false);

  const location = useLocation();
  let history = useHistory();

  const product = location.state
    ? location.state.servicioSeleccionado
    : items.length > 0
    ? items[0]
    : history.push("/servicios");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSpinner = () => {
    setTimeout(() => {
      setSpinner(true);
    }, 1000);
  };
  handleSpinner();

  //console.log(product, "product desde seleccion sucursal");

  return (
    <Row className="m-0 p-0 mt-5 mb-5">
      <Col xs={12} md={{ span: 10, offset: 1 }} className="mb-5">
        <h3 style={{ fontFamily: "Renault Bold" }}>
          Ubicación de nuestro punto de servicio{" "}
        </h3>
        {spinner ? (
          <>
            <Row className="m-0 p-0 mt-5 justify-content-center">
              <Col xs={12} md={4} className="card m-0 p-0 mt-3">
                <a
                  href="https://goo.gl/maps/pP2HLSMshqBAUH358"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "black" }}
                >
                  {/* <Link
                  to={{
                    pathname: "/servicios/seleccionTurno",
                    state: {
                      sucursalSeleccionada: product && {
                        ...product,
                        sucursal: "Lavallol 3555",
                      },
                    },
                  }}
                  style={{ color: "black" }}
                  onClick={() => handleSeleccionSucursal("Lavallol 3555")}
                > */}
                  <Image
                    src="https://store.clama.com.ar/img/imgSitio/1.jpg"
                    alt="foto sucursal beiro 3071"
                    style={{ border: "1px solid lightGrey" }}
                    width="100%"
                  />
                </a>
                {/*  </Link> */}
                <Row className="m-0 p-0 justify-content-center">
                  <a
                    href="https://goo.gl/maps/pP2HLSMshqBAUH358"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "black" }}
                  >
                    <Col
                      xs={12}
                      className="text-center d-flex align-items-center"
                      style={{ height: "35px" }}
                    >
                      Ver en Google Maps
                    </Col>
                  </a>
                </Row>
              </Col>
            </Row>
            <Row className="m-0 p-0">
              <Col xs={12} className="text-center mt-5">
                <Link
                  to={{
                    pathname: "/servicios/seleccionTurno",
                    state: {
                      sucursalSeleccionada: product && {
                        ...product,
                        sucursal: "Beiro 3071",
                      },
                    },
                  }}
                  style={{ color: "black" }}
                  onClick={() => handleSeleccionSucursal("Beiro 3071")}
                >
                  <Button
                    variant="outline-light"
                    style={{
                      border: "1px solid black",
                      fontWeight: "bold",
                      fontFamily: "Renault Bold",
                      borderRadius: "0px",
                      color: "black",
                    }}
                  >
                    Continuar
                  </Button>
                </Link>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="m-0 p-0 my-5 d-flex justify-content-center">
              <Col xs={{ span: 2, offset: 1 }}>
                <Spinner animation="border" />
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
}
