import React, { useContext } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uiContext } from "../../contexts/UiContextProvider";

import { Row, Col } from "react-bootstrap/";

import { ShowAt } from "react-with-breakpoints";

import Image from "react-bootstrap/Image";
import logoClama from "../../assets/images/logo.png";
import logoRenault from "../../assets/images/marca.png";

import { MARCAS } from "../../constants/constants";

export default function NavigationBar() {
  const uiManager = useContext(uiContext);

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
  };

  return (
    <Navbar
      fixed="top"
      className="shadow-sm text-center p-0 navigationBarStyle"
      style={{ zIndex: "3" }}
    >
      <Navbar.Brand>
        <Link to="/" className="styled-nav">
          <Col className="pl-3">
            <Image width="140px" src={logoClama} />
          </Col>
        </Link>
      </Navbar.Brand>

      <ShowAt breakpoint="largeAndAbove">
        <Nav>
          <Row
            className="m-0 p-0 pt-2 font-weight-bold"
            style={{ fontSize: "13px" }}
          >
            <Col xs={12} className="m-0 p-0">
              <Row className="m-0 p-0">
                <a
                  href="https://store.clama.com.ar/vtf/"
                  className="styled-nav"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Col className="m-0 p-0 pr-3">VEHICULOS</Col>
                </a>

                <Link
                  to={{
                    pathname: "/usados/catalogo",
                    state: { product: { marca: MARCAS.OTRAS } },
                  }}
                  onClick={() => handleButtonClick({ marca: MARCAS.OTRAS })}
                  className="styled-nav"
                >
                  <Col className="m-0 p-0 pr-3">USADOS</Col>
                </Link>

                {/*  <a
                  href="https://store.clama.com.ar/cotizador/"
                  className="styled-nav"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Col className="m-0 p-0 pr-3">COTIZA TU USADO</Col>
                </a>

                <a
                  href="https://store.clama.com.ar/rci/"
                  className="styled-nav "
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Col className="m-0 p-0 pr-3">FINANCIACION ONLINE</Col>
                </a> */}

                <Link to="/planes" className="styled-nav ">
                  <Col
                    className="m-0 p-0 pr-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    PLAN ROMBO
                  </Col>
                </Link>

                <Link to="/servicios" className="styled-nav ">
                  <Col className="m-0 p-0 pr-3">SERVICIOS</Col>
                </Link>
                <Link to="/accesorios" className="styled-nav">
                  <Col className="m-0 p-0 pr-3">ACCESORIOS</Col>
                </Link>
                <Link to="/repuestos" className="styled-nav">
                  <Col className="m-0 p-0 pr-3">REPUESTOS</Col>
                </Link>
              </Row>
            </Col>
          </Row>
        </Nav>
      </ShowAt>
      <Nav className="mr-auto"></Nav>
      <Navbar.Brand className="mr-0">
        <a href="https://www.renault.com.ar" target="_blank" rel="noreferrer">
          <Col className="pr-3">
            <Image src={logoRenault} className="marcaWidth" />
          </Col>
        </a>
      </Navbar.Brand>
      <Nav>
        {/* <Navbar.Brand
          style={{ fontSize: "17px", paddingTop: "10px", marginRight: "5px" }}
        >
          menu
        </Navbar.Brand> */}
        <Button
          className="d-block d-md-none without-focus-border"
          variant="transparent"
          onClick={uiManager.sideBar.show}
          style={{ paddingTop: "10px" }}
        >
          <FontAwesomeIcon icon="bars" style={{ color: "white" }} />
        </Button>
        <Button
          className="d-none d-md-block btn-outline-dark"
          variant="transparent"
          onClick={uiManager.sideBar.toggleCollapsed}
          style={{ paddingTop: "5px", marginRight: "10px", border: "none" }}
        >
          <FontAwesomeIcon icon="bars" style={{ color: "white" }} />
        </Button>
        <ShowAt breakpoint="mediumAndAbove"></ShowAt>
      </Nav>
    </Navbar>
  );
}
