import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

export default function Sucursales() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Row className="m-0 p-0 my-5">
      <Col xs={12} lg={{ span: "8", offset: "2" }}>
        <Row className="m-0 p-0">
          <a
            href="https://youtu.be/m00ov3kUq0c"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://store.clama.com.ar/img/imgSitio/turorialSucursal.png"
              alt="video sucursal"
              style={{ borderRadius: "5px" }}
              width="100%"
            />
          </a>
        </Row>
        <Row
          className="m-0 p-0  mt-3"
          style={{ borderRadius: "5px", backgroundColor: "lightGray" }}
        >
          <Col className="pt-2">
            <h3>Sucursales</h3>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>administración, ventas y servicios</h3>
            <br /> <b>Av. Francisco Beiro 3071 (C1419HYE)</b> Ciudad de Buenos
            Aires,
            <br />
            Argentina 011 4505-8600
            <br />
            Lun a Vie 9:00 a.18.30hs
            <br />
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105193.43279518775!2d-58.56936063805014!3d-34.52075898209141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb6310aa342e3%3A0x9d978e1d89edc87b!2sConcesionario%20Renault%20-%20CABA%20-%20Clama%20S.A.%20(Casa%20Central)!5e0!3m2!1sen!2sar!4v1692295349741!5m2!1sen!2sar"
              width="100%"
              height="200"
              style={{ border: "0" }}
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              title="Av. Francisco Beiró 3071, Cdad. Autónoma de Buenos Aires"
            />
          </Col>
        </Row>
        <hr />

        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>ventas</h3>
            <br /> <b>Av. Francisco Beiro 3443 (C1419HYI)</b> Ciudad de Buenos
            Aires,
            <br />
            Argentina 011 4505-8637
            <br />
            Lun a Vie 9:00 a.19.00hs
            <br />
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105193.43279518775!2d-58.56936063805014!3d-34.52075898209141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb7af3dcf6e09%3A0x440f793ebb60ecf2!2sConcesionario%20Renault%20-%20CABA%20-%20Clama%20S.A.%20(Villa%20del%20Parque)!5e0!3m2!1sen!2sar!4v1692295668107!5m2!1sen!2sar"
              width="100%"
              height="200"
              style={{ border: "0" }}
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              title="Av. Francisco Beiró 3443, Cdad. Autónoma de Buenos Aires"
            />
          </Col>
        </Row>
        <hr />

        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>ventas</h3>
            <br /> <b>Av. San Martin 7080 (C1419ICU)</b> Ciudad de Buenos Aires,
            <br />
            Argentina 011 4505-8638
            <br />
            Lun a Vie 9:00 a.19.00hs
            <br />
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105193.43279518775!2d-58.56936063805014!3d-34.52075898209141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb7b405beef93%3A0xa32baaebf7ec2e05!2sConcesionario%20Renault%20-%20CABA%20-%20Clama%20S.A.%20(Villa%20Devoto)!5e0!3m2!1sen!2sar!4v1692295911765!5m2!1sen!2sar"
              width="100%"
              height="200"
              style={{ border: "0" }}
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              title="Av. San Martin 7080, Cdad. Autónoma de Buenos Aires"
            />
          </Col>
        </Row>
        <hr />

        <Row className="m-0 p-0 mt-4">
          <Col xs={12} lg={6} className="mt-3">
            <h3>
              ventas y <b>RENAULT</b> minuto
            </h3>
            <br /> <b>Av. Ricardo Balbin 2960 (C1430AAN)</b> Ciudad de Buenos
            Aires,
            <br />
            Argentina 011 4505-8639
            <br />
            Lun a Vie 9:00 a.19.00hs
            <br />
          </Col>
          <Col xs={12} lg={6} className="d-flex align-items-center mt-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105193.43279518775!2d-58.56936063805014!3d-34.52075898209141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb68668c2ed7d%3A0xff46f063d16f6d04!2sConcesionario%20Renault%20-%20CABA%20-%20Clama%20S.A.%20(Coghlan)!5e0!3m2!1sen!2sar!4v1692296217016!5m2!1sen!2sar"
              width="100%"
              height="200"
              style={{ border: "0" }}
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              title="Av. San Martin 7080, Cdad. Autónoma de Buenos Aires"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
