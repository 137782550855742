import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import useGastos from "./hooks/useGastos";

import { imagPathUsados, capitalize } from "../../constants/constants";
import { Link } from "react-router-dom";

export default function Gastos() {
  const {
    product,
    handlePatentamientoSeleccionado,
    patentamientoSeleccionado,
    handleModal,
    lgShow,
    terminos,
    handleTerminos,
    handleProductReadytoPay,
    productReadytoPay,
  } = useGastos();
  //console.log("🚀 ~ product:", product);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Row className="m-0 p-0 my-5">
        <Col xs={12} md={{ span: 4, offset: 1 }}>
          <img
            src={imagPathUsados + product?.foto0}
            alt="foto vehiculo seleccionado"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "3px",
            }}
          />
        </Col>
        <Col xs={12} md={6} style={{ height: "auto" }} className="mt-4">
          <h2>
            {product && capitalize(product.marca)}, {product?.modelo}
          </h2>
          <h5>elegi el paquete de gastos que más te convenga</h5>
          <Row className="m-0 p-0 mt-4">
            <Col xs={12} md={12} xl={8}>
              <Form>
                <Row className="m-0 p-0">
                  <Col xs={12}>
                    <Row
                      className="m-0 p-2"
                      style={{
                        backgroundColor: "black",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <Col xs={2} md={2}>
                        <Form.Check
                          onClick={() => {
                            handlePatentamientoSeleccionado({
                              tipo: "con nosotros",
                              valor: product.pat_emp,
                              aGuardarEnBase: "Con nosotros",
                            });
                          }}
                          inline
                          name="group1"
                          type="radio"
                          id="conNosotros"
                        />
                      </Col>
                      <Col xs={8} md={10}>
                        Transferis con nosotros
                      </Col>
                    </Row>
                    <Col className="mt-3">
                      <h4>
                        Precio:{" "}
                        {product && product.moneda === "u$d" ? "USD " : "$"}
                        {Intl.NumberFormat("es-AR", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(product?.pat_emp)}
                      </h4>
                      <span style={{ fontSize: "13px" }}>
                        <b>(Válido para Capital Federal y GBA)</b>
                      </span>

                      <ul className="mt-4">
                        <li>Nos brindás tu documentación.</li>
                        <li>Te tomamos las firmas necesarias.</li>
                        <li>Retirás la unidad.</li>
                      </ul>
                    </Col>
                  </Col>
                </Row>
                <Row className="m-0 p-0 mt-5">
                  <Col xs={12}>
                    {" "}
                    <Row
                      className="m-0 p-2"
                      style={{
                        backgroundColor: "black",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <Col xs={2} md={2}>
                        <Form.Check
                          onClick={() => {
                            handlePatentamientoSeleccionado({
                              tipo: "por tu cuenta",
                              valor: product.pat_cli,
                              aGuardarEnBase: "Por su cuenta",
                            });
                          }}
                          inline
                          name="group1"
                          type="radio"
                          id="porTuCuenta"
                        />
                      </Col>
                      <Col xs={8} md={10}>
                        Transferis por tu cuenta
                      </Col>
                    </Row>
                    <Col className="mt-3">
                      <h4>
                        Precio:{" "}
                        {Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(product?.pat_cli)}
                      </h4>
                      <span style={{ fontSize: "13px" }}>
                        <b>(Válido para Capital Federal y GBA)</b>
                      </span>

                      <ul className="mt-4">
                        <li>Te entregamos todos los formularios necesarios.</li>
                        <li>Presentás la documentación en el registro.</li>
                        <li>
                          Con la transferencia lista programamos la entrega.
                        </li>
                        <li>Retirás la unidad.</li>
                      </ul>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={12} md={12} xl={4} className="m-0 p-0 mt-3">
              <Row
                className="m-0 p-0 mt-4"
                style={{ backgroundColor: "lightGray", borderRadius: "5px" }}
              >
                <Col xs={12} className="text-center mt-4 ">
                  <h4>
                    <b>Precio Final</b>
                  </h4>
                  {patentamientoSeleccionado.tipo}
                </Col>
                <Col xs={12} className="text-center mt-3">
                  <h4>
                    <b>
                      {product && product.moneda === "u$d" ? "USD " : "$ "}
                      {product &&
                        Intl.NumberFormat("es-AR", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(
                          product.precio + patentamientoSeleccionado.valor
                        )}
                    </b>
                  </h4>
                </Col>
                <Col xs={12} className="text-center mt-3 mb-4">
                  <Button
                    onClick={() => handleModal(true)}
                    size="sm"
                    variant="outline-light"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "black",
                      fontWeight: "bold",
                      fontFamily: "Renault Bold",
                      borderRadius: "0px",
                      color: "white",
                    }}
                    disabled={patentamientoSeleccionado.tipo === "sin gastos"}
                  >
                    reservar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={lgShow}
        onHide={() => handleModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Terminos y condiciones
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "scroll", height: "600px" }}>
          <p>
            <b>1- PEDIDO:</b> La presente SOLICITUD DE RESERVA tiene el caracter
            de irrevocable e instransferible y la firma vendedora ser reserva la
            facultad de aceptarla o rechazarla en cualquier momento sin
            limitacion alguna entendiendose concretada la compra recien en el
            momento de entrega de la unidad y en el caso de que el comprador
            desistiese de la compra todo deposito efectuado en aplicacion a la
            misma quedara definitiva y automaticamente en poder de la firma
            vendedora como indemnizacion a excepcion de los casos previstos en
            los arts. 2do y 3ro. Los valores recibidos constituiran seña recien
            cuando se emita recibo oficial.
            <br />
            <br />
            <b>2- PRECIO:</b> Los precios de ventas consignados para la unidad y
            accesorios son los de la lista al publico actuales fijados por
            RENAULT ARGENTINA S.A. y al igual que las condiciones de pagos
            propuestas, podran variarse en cualquier momento y sin previo aviso,
            rigiendo en este caso y para esta solicitud los nuevos precios de
            venta. En el caso de producirse esta eventualidad el comprador
            tendra derecho a anular su pedido y solicitar por nota el reintegro
            de los depositos efectuados dentro de los quince (15) dias
            subsiguientes a la fecha de variacion, los cuales seran puestos a
            disposicion en un plazo no mayor de treinta (30) dias, sin
            indemnizacion alguna.
            <br />
            <br />
            <b>3- FECHA DE ENTREGA:</b> La fecha de entrega indicada es
            meramente informativa y no compromete a la firma vendedora a su
            cumplimiento cuando se originen atrasos en los despachos de fabrica,
            inconvenientes en los transportes, huelgas u otras causas de fuerza
            mayor, y toda otra circunstancia equivalente. Pasados noventa (90)
            dias de esa fecha, el comprador podra dentro de los quince (15) dias
            subsiguientes, dejar sin efecto su pedidos con derecho a reintegro
            de todo deposito efectuado, sin indemnizacion alguna. Vencido este
            termino, el mismo quedara prorrogado automaticamente por un nuevo
            periodo.
            <br />
            <br />
            <b>4- ESPECIFICACIONES:</b> RENAULT ARGENTINA S.A. se reserva el
            pleno derecho de cancelar la produccion, revisar, suspender o
            alterar las especificaciones tecnicas de sus vehiculos o cualquier
            pieza de los mismos en cualquier momento y sin previo aviso, sin que
            la fabrica ni la firma vendedora asuma la responsabilidad o
            compromiso alguno con el comprador, no estando tampoco obligada a
            aplicar a las unidades ya construidas o en proceso de construccion,
            las modificaciones que hubiesen sido proyectadas al recibo de esta
            solicitud
            <br />
            <br />
            <b>5- FINANCIACION:</b> En las operaciones en que se convenga
            acordar facilidades para el pago del precio de venta, el comprador
            se obliga a gravar la unidad adquirida con garantia prendaria en
            primer grado por el saldo del precio mas los gastos de financiacion
            a favor del vendedor o entidad financiera, en su caso. A los efectos
            de considerar la respectiva SOLICITUD DE CREDITO, la firma vendedora
            queda autorizada a recabar informes comerciales, bancarios,
            particulares, etc., que estima necesarios y en caso de proceder a su
            rechazo o comprobar falsedad en los datos suministrados, retendra de
            todo deposito realizado, los gastos incurridos.
            <br />
            <br />
            <b>6- SEGURO:</b> En las operaciones financiadas el comprador queda
            obligado a contratar una poliza de seguro por ante la compañia
            aseguradora que designe la firma vendedora o entidad financiera,
            contra todo riesgo, inclusive huelgas, hechos belicos, tumultos,
            inundaciones, terremotos y granizos y mantener vigente dicha poliza
            por todo el periodo de la financiacion. En el acto de tomar posesion
            del bien adquirido, el comprador ebera entregar a la firma vendedora
            el original de la poliza debidamente endosada, conjuntamente con el
            recibo oficial que justifique el pago total de la prima.
            <br />
            <br />
            <b>7- ENTREGA:</b> El comprador debera retirar y abonar totalmente
            la unidad adquirida y los gastos correspondientes dentro de los diez
            (10) dias desde la comunicacion respectiva. Vencido dicho termino y
            por el solo transcurso del tiempo y sujeto a lo dispuesto en el art.
            1ro para el supuesto de anulacion por parte del comprador.
            <br />
            <br />
            <b>8- UNIDAD USADA:</b> El valor de toda unidad usada ofrecida como
            parte de pago sera acreditado por la firma vendedora unicamente en
            caso de recibir la misma dentro de las venticuatro (24) horas
            subsiguientes de haberse formalizado el respectivo pedido por la
            unidad nueva, caso contrario el peritaje efectuado tiene caracter
            "INFORMATIVO", y sujeto a una revalidacion, siendo en este caso
            facultad privativa del vendedor aceptar o rechazar la unidad usada.
            Al valor convenido se deducira el impuesto a la transferencia de
            Automoviles Usados (Ley 21.432).
            <br />
            <br />
            <b>9-</b> Todos los pagos que demande el transporte de la unidad,
            gastos e impuestos por patentamiento y la constitucion de prenda,
            corren por exclusiva cuenta del comprador.
            <br />
            <br />
            <b>10-</b> Cualquier error en las condiciones o en las sumas que
            figure en el presente pedido, en caso de comprobarse posteriormente,
            se ajustaran a su real valor.
            <br />
            <br />
            <b>11-</b> Cuando la firma vendedora tenga disponible el vehiculo
            solicitado, notificara tal circunstancia al comprador, quien debera
            hacerse presente para formalizar y ajustar las condiciones
            pendientes de la operacion dentro de los tres (3) dias habiles de
            recibila la notificacion, en cado de que el comprador no compareza
            en el plazo indicado o no cumpla con las condiciones pactadas en el
            lapso de dos (2) dias habiles posteriores a la formalizacion de la
            compra perdera el derecho al turno de entrega pasando a una lista de
            espera, sin perjuicio de las demas estipulaciones contractuales
            contenidas en el presente.
            <br />
            <br />
            <b>12-</b> Los valores recibidos en deposito por la presente
            solicitud de reserva se acreditaran unicamente con la emision del
            recibo oficial correspondiente. 13- En caso de falta de pago del
            precio por parte del comprador en los plazos estipulados, se
            producira su mora de pleno derecho, la caducidad de los plazos
            pendientes y consecuentemente la rescision de la compra celebrada,
            pudiendo Clama S.A. disponer del automovil para otro interesado.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Row className="m-0 p-0">
            <Col xs={9} className="m-0 p-0">
              <Form.Group controlId="formBasicCheckbox" className="m-0 p-0">
                <Form.Check
                  onClick={() => {
                    handleTerminos(!terminos);
                    handleProductReadytoPay();
                  }}
                  style={{ fontSize: "12px" }}
                  type="checkbox"
                  label="Al marcar esta casilla, confirmo que he leído y acepto cumplir los terminos y condiciones de Clama S.A."
                />
              </Form.Group>
            </Col>
            <Col xs={3} className="m-0 p-0 text-center">
              <Link
                to={{
                  pathname: "/tarjetas",
                  state: {
                    product: productReadytoPay && productReadytoPay,
                  },
                }}
                style={{ color: "black" }}
              >
                <Button
                  size="sm"
                  variant="outline-light"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                    fontFamily: "Renault Bold",
                    borderRadius: "0px",
                    color: "black",
                  }}
                  disabled={!terminos}
                >
                  continuar
                </Button>
              </Link>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
