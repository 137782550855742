import { useState, useEffect } from "react";

export default function useFicha() {
  const [items, setItems] = useState(localStorage);
  console.log("🚀 ~ items:", items);
  const [productReadytoPay, setProductReadytoPay] = useState([]);
  console.log("🚀 ~ productReadytoPay:", productReadytoPay);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items) {
      setItems([items]);
      handleProductReadytoPay(items);
    }
  }, []);

  const handleProductReadytoPay = (product) => {
    setProductReadytoPay({
      id: product.id,
      modelo: product.modelo,
      motor: "",
      foto: product.foto0,
      servicio: "",
      precio: product.precio_plan,
      moneda: "$",
      origen: "planes",
      sucursal: "",
      turno: "",
      patentamiento: "",
    });
  };

  return { items, productReadytoPay };
}
