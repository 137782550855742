import { modelos, miniaturas } from "../data/data";
export default function useModelos() {
  const randomMiniatura = () => {
    const automoviles = miniaturas.automoviles;
    const utilitarios = miniaturas.utilitarios;
    const pickup = miniaturas.pickup;

    const automovilesRandom = Math.floor(Math.random() * automoviles.length);
    const utilitariosRandom = Math.floor(Math.random() * utilitarios.length);
    const pickupRandom = Math.floor(Math.random() * pickup.length);

    const randomProducts = [automovilesRandom, utilitariosRandom, pickupRandom];

    return randomProducts;
  };

  return { modelos, randomMiniatura, miniaturas };
}
