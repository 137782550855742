import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import familia from "../../assets/images/secciones/rcs/familia.jpg";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useNews from "./hooks/useNews";

import "../../css/index.css";

export default function Rcs() {
  const { renderBoxes, renderServiciosSugeridos } = useNews();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Row className="m-0 p-0 text-center mb-4">
        <Col className="d-flex align-items-center justify-content-center backgroundStyle">
          <p>
            <h2>RENAULT CARE SERVICE</h2>
          </p>
          <p style={{ fontSize: "15px" }}>
            Quien mejor que Renault para cuidar tu Renault.
            <br />
            Tenemos planes de mantenimiento pensados para tu auto, para vos y tu
            bolsillo.
          </p>
        </Col>
      </Row>
      <Row className="m-0 p-0 text-center mb-5">
        <Col
          xs={12}
          className="m-0 p-5"
          style={{ backgroundColor: "#f2f2f2", fontFamily: "Renault Bold" }}
        >
          ¿POR QUÉ ES TAN IMPORTANTE HACER EL SERVICE OFICIAL PRECONIZADO POR
          RENAULT?
        </Col>
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="mt-4">
          <Row>{renderBoxes()}</Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-3 text-center">
        <Col className="mt-3">
          <h2 style={{ fontFamily: "Renault Bold" }}>Operaciones sugeridas</h2>
          <p>Nada mejor que Renault para sugerirte cómo mantener tu Renault</p>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        <Col
          xs={12}
          lg={{ span: 4, offset: 2 }}
          className=" text-center d-flex align-items-center justify-content-center"
        >
          <img src={familia} alt="familia" width="100%" />
        </Col>
        <Col xs={12} lg={{ span: 4 }}>
          <Row className="m-0 p-0 mt-5 text-left">
            <Col>
              <h4 style={{ fontFamily: "Renault Bold" }}>
                Te proponemos realizar las siguientes operaciones cada vez que
                hagas el service
              </h4>
              <p>
                Contamos con todo el equipamiento necesario para garantizar el
                funcionamiento óptimo de tu vehículo, lo que hará que no te
                preocupes por nada.
                <br />
                <br />
                Nosotros nos ocupamos de cuidar tu auto, vos solamente encargate
                de disfrutarlo.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-3">
        <Col xs={12} lg={{ span: 8, offset: 2 }} className=" text-center">
          <Row>{renderServiciosSugeridos()}</Row>
        </Col>
      </Row>

      <Row className="m-0 p-0 mb-5 text-center">
        <Col xs={{ span: 8, offset: 2 }} lg={{ span: 2, offset: 5 }}>
          <Link to="/servicios">
            <Row>
              <Col
                style={{
                  height: "40px",
                  color: "white",
                  backgroundColor: "black",
                  paddingTop: "7px",
                  cursor: "pointer",
                }}
                className="mb-3"
              >
                agendar turno
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5 text-center">
        <Col xs={12} lg={{ span: 8, offset: 2 }}>
          <h3 style={{ fontFamily: "Renault Bold" }}>Confianza Renault</h3>
          <p>
            La red Renault está comprometida a hacer tu vida más fácil
            <br />
            <br />
            Renault Servicios se encarga de todo para tu tranquilidad y
            seguridad. Al confiarnos el mantenimiento y reparación de tu
            Renault, podés estar seguro de que todo será rápido y fácil: equipos
            para escucharte y aconsejarte, repuestos Renault originales de alta
            calidad y soluciones a la medida independientemente de la tarea a
            realizar o de la antigüedad de tu vehículo.
          </p>
        </Col>
      </Row>
    </>
  );
}
