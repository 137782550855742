import React from "react";
import { Button /* , Image */ } from "react-bootstrap";
//import whatsappAlternativo from "../../assets/images/logos/whatsappAlternativo.png";
import { capitalize } from "../../constants/constants";

export default function WhatsappContactButton({ modelo, origen }) {
  //console.log(modelo, "modelo en whatsapp contact button");

  switch (origen) {
    case "usados":
      return (
        <a
          href={`https://wa.me/5491145058600?text=Hola, estoy interesado en el modelo ${capitalize(
            modelo.modelo.toLowerCase()
          )}, chasis: ${
            modelo.vin
          } condicion ${modelo.cs_tipo.toLowerCase()}. Mi consulta es:%20`}
        >
          {/* <Image
            className="ml-1"
            src={whatsappAlternativo}
            alt="whatsapp logo"
            style={{
              width: "22px",
            }}
          /> */}
          <Button
            size="sm"
            variant="outline-light"
            style={{
              border: "1px solid black",
              fontWeight: "bold",
              fontFamily: "Renault Bold",
              borderRadius: "0px",
              color: "black",
            }}
          >
            contactar
          </Button>
        </a>
      );

    case "accesorios":
    case "repuestos":
      return (
        <a
          href={`https://wa.me/5491145058600?text=Hola, estoy interesado en ${modelo.descripcion}, numero de pieza: ${modelo.codigo}`}
        >
          {/*  <Image
            className="ml-1"
            src={whatsappAlternativo}
            alt="whatsapp logo"
            style={{
              width: "22px",
            }}
          /> */}
          <Button
            size="sm"
            variant="outline-light"
            style={{
              border: "1px solid black",
              fontWeight: "bold",
              fontFamily: "Renault Bold",
              borderRadius: "0px",
              color: "black",
            }}
          >
            contactar
          </Button>
        </a>
      );

    default:
      break;
  }
}
