import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import facebookImg from "../../assets/images/logos/facebook.svg";
import instagramImg from "../../assets/images/logos/instagram.svg";
import youTubeImg from "../../assets/images/logos/youtube.svg";
import linkedInImg from "../../assets/images/logos/linkedin.svg";
import tiktokImg from "../../assets/images/logos/tiktok.svg";
import app from "../../assets/images/app.png";
import apple from "../../assets/images/apple.png";
import google from "../../assets/images/google.png";

import { Link } from "react-router-dom";

export default function Footer({
  brand,
  brandText,
  branches,
  branchesText,
  socialMedia,
  copyright,
}) {
  return (
    <Row className="m-0 p-0">
      <Col className="footerCustomStyleTop">
        <Row className="footerCustomStyleBottom text-center">
          <Col xs={12} lg={4}>
            <Row className="m-0 p-0 mt-5">
              <Col>
                <h3>{brand}</h3>
              </Col>
            </Row>
            <Row className="m-0 p-0 mt-2">
              <Col>{brandText}</Col>
            </Row>
            <Row className="m-0 p-0 mt-5">
              <Col>
                <a
                  href={socialMedia.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image width="35px" src={instagramImg} alt="instagram" />
                </a>
                <a
                  href={socialMedia.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image width="15px" src={facebookImg} alt="facebook" />
                </a>
                <a
                  href={socialMedia.youtube}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image width="43px" src={youTubeImg} alt="youtube" />
                </a>
                <a
                  href={socialMedia.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image
                    width="33px"
                    src={linkedInImg}
                    alt="linkedin"
                    className="mb-1"
                  />
                </a>
                <a
                  href={socialMedia.tiktok}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-4"
                >
                  <Image
                    width="33px"
                    src={tiktokImg}
                    alt="linkedin"
                    className="mb-1"
                  />
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            {/* COMENTAR AL MOMENTO DE HACER EL SUBMIT A PLATFORMS */}
            <Col xs={12}>
              <img src={app} alt="app" width="20%" className="pt-5" />
            </Col>
            <Row className="m-0 p-0">
              <Col xs={6} className="text-right m-0 p-0">
                <a
                  href="https://apps.apple.com/ar/app/clama-s-a/id1444754754"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={apple} alt="apple" width="45%" />
                </a>
              </Col>
              <Col xs={6} className="text-left m-0 p-0">
                <a
                  href="https://play.google.com/store/apps/details?id=io.ionic.clama&hl=es&pli=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={google} alt="google" width="45%" />
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            <Link to="/sucursales">
              <Row className="m-0 p-0 mt-5 text-white">
                <Col>
                  <h3>{branches}</h3>
                </Col>
              </Row>
              <Row className="m-0 p-0 mt-2 text-white">
                <Col>{branchesText}</Col>
              </Row>
            </Link>
            <Row className="p-0" style={{ margin: "60px 0 60px 0" }}>
              <Col style={{ fontSize: "15px" }}>{copyright}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
