import React from "react";

import { Card, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function GenericCard({
  title,
  text,
  image,
  imageFixedPath,
  link,
  price,
  buttonBackgroundColor,
  action,
  to,
  product,
  handleButtonClick,
  km,
  anio,
  transmision,
  from,
  whatsappButton,
  compatible,
}) {
  const imgToRender = () => {
    return (
      <Card.Img
        variant="top"
        style={{
          maxWidth: "100%",
          maxHeight: "14rem",
          minHeight: "14rem",
          objectFit: "cover",
          borderRadius: "0px",
        }}
        src={imageFixedPath + image}
      />
    );
  };

  //console.log(product, from, "desde generic card");

  return (
    <Card
      className="shadow my-2"
      style={{
        width: "100%",
        height: "auto",
        maxHeight: "34rem",
        borderRadius: "0px",
      }}
    >
      {/*  <Card.Title className="text-center mt-2 font-weight-bold">
        <h3>{text}</h3>
      </Card.Title> */}
      {from ? (
        <Link
          to={{
            pathname: link,
            state: {
              product: product,
            },
          }}
          onClick={() => handleButtonClick(product)}
        >
          {imgToRender()}
        </Link>
      ) : (
        imgToRender()
      )}
      <Card.Body style={{ backgroundColor: "#f5f5f5" }}>
        <Card.Title
          style={{ minHeight: "3rem", borderBottom: "1px solid lightgray" }}
        >
          {title}
        </Card.Title>
        <Card.Text className="m-0 p-0">
          {product && product.marca === "DUCATI" ? "MOTOCICLETA" : text}
        </Card.Text>
        {anio || km || transmision ? (
          <Card.Text className="m-0 p-0" style={{ fontSize: "12px" }}>
            {anio} | {transmision} | <b>km</b> {km}
            <br></br>
          </Card.Text>
        ) : (
          <Card.Text className="m-0 p-0" style={{ fontSize: "12px" }}>
            {compatible}
          </Card.Text>
        )}
        <Card.Text className="mt-3">
          <b>
            {product && product.moneda === "u$d" ? "USD " : "$ "}
            {price}
          </b>
        </Card.Text>

        <Row className="m-0 p-0">
          <Col xs={10} className="m-0 p-0">
            <Link
              to={{
                pathname: to,
                state: {
                  product: product,
                  link: link,
                },
              }}
            >
              <Button
                size="sm"
                onClick={() => handleButtonClick(product)}
                variant="outline-light"
                className="styled-nav"
                style={{
                  backgroundColor: buttonBackgroundColor,
                  borderColor: "transparent",
                  fontWeight: "bold",
                  fontFamily: "Renault Bold",
                  borderRadius: "0px",
                }}
              >
                {action}
              </Button>
            </Link>
            {whatsappButton && whatsappButton}
          </Col>
          {from !== "planes" && (
            <Col xs={2} className="text-right">
              <a
                href={`https://wa.me?text=El link para compartir este producto es: https://store.clama.com.ar/v2/${encodeURIComponent(
                  "#"
                )}/${from}${
                  from === "usados" ? "/catalogo?idInterno=" : "?id="
                }${from === "usados" ? product.id_interno : product.id}`}
                data-action="share/whatsapp/share"
              >
                <FontAwesomeIcon
                  icon="share"
                  style={{ color: "black" }}
                  className="mt-2"
                />
              </a>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}
