import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";

import news3 from "../../assets/images/secciones/news3.jpg";
import bombero from "../../assets/images/secciones/bombero.jpg";
import adas from "../../assets/images/secciones/adas.jpg";

export default function Hf() {
  const [videoLoaded, setVideoLoaded] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Row className="m-0 p-0 mb-5">
        <Col xs={12} className="m-0 p-0">
          {!videoEnded && (
            <video
              controls={false}
              autoPlay
              muted
              loop
              preload={"auto"}
              src="https://store.clama.com.ar/v2/videos/hf.mp4"
              type="video/mp4"
              className="d-block w-100"
              width={"100%"}
              onLoadedData={() => setVideoLoaded(false)}
              onEnded={() => setVideoEnded(true)}
            >
              Tu navegador no soporta videos.
            </video>
          )}

          <div className="hf">
            <b>La seguridad del automóvil vista por Renault</b>
          </div>

          {videoLoaded && (
            <>
              <Row className="text-center">
                <Col
                  className="ml-3"
                  style={{ position: "absolute", top: "40%" }}
                >
                  <Spinner animation="border" role="status" />
                  <br />
                  cargando video
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 text-center">
        <Col xs={12} lg={{ span: 4, offset: 2 }}>
          <img src={news3} alt="humans firts" width="100%" />
        </Col>
        <Col xs={12} lg={4}>
          <Row className="m-0 p-0 my-5 text-center">
            <Col>
              <h2 style={{ fontFamily: "Renault Bold" }}>
                Programa Primero el Ser Humano
              </h2>
              <p>
                <b>
                  Mejorar la seguridad de todos los usuarios de la carretera
                </b>
              </p>
              <p>
                Intercambio de datos, pruebas de choque, ejercicios a gran
                escala, Renault está decidido a reducir el número y la gravedad
                de los accidentes. Su "programa humano primero", desarrollado
                con investigadores, expertos y bomberos, tiene un único
                objetivo: mejorar la seguridad de todos los usuarios de la
                carretera todos los días.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-0 p-0 my-5 text-center">
        <Col xs={12} lg={{ span: 8, offset: 2 }}>
          <h2 style={{ fontFamily: "Renault Bold" }}>
            Renault y bomberos, Una asociación única y sin precedentes
          </h2>

          <p>
            Como parte del programa Human First, Renault se ha asociado con los
            hermanos Naudet, los directores de los galardonados documentales del
            11 de septiembre o el incendio de Notre Dame, para destacar la rica
            colaboración entre el Grupo y los equipos de rescate en carretera.
            Es una aventura excepcional, la alianza de ingeniería e
            investigación y desarrollo al servicio de la "hora dorada", esos
            preciosos minutos durante los cuales los rescatistas harán todo lo
            posible para salvar vidas.
          </p>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 text-center">
        <Col xs={12} lg={{ span: 4, offset: 2 }}>
          <img src={bombero} alt="humans firts" width="100%" />
        </Col>
        <Col xs={12} lg={4}>
          <Row className="m-0 p-0 my-5 text-center">
            <Col>
              <h2 style={{ fontFamily: "Renault Bold" }}>
                Entrenamiento de bomberos
              </h2>

              <p>
                El único fabricante en el mundo que ha integrado un bombero
                teniente coronel a tiempo completo en su ingeniería, y que
                colabora regularmente con los equipos de rescate en carretera,
                Renault ha formado a 5.000 bomberos de 19 países de todo el
                mundo en pocos años. A partir de 2023, ya están previstos nuevos
                cursos de formación en Europa y América Latina.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 text-center">
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="mt-5">
          <h2 style={{ fontFamily: "Renault Bold" }}>
            Seguridad, vehículos de última generación
          </h2>

          <p>
            En 50 años, Renault ha ayudado a dividir por 5 el número de víctimas
            en las carreteras. Para mejorar continuamente la seguridad de todos,
            los expertos del LAB (Laboratorio de Accidentología y Biomecánica) y
            los 600 ingenieros y técnicos innovan incansablemente. En total, se
            han presentado más de 2.000 patentes y una gama de soluciones de
            última generación como el Fireman Access o el SD Switch.
          </p>
        </Col>
      </Row>
      <Row className="dflex align-items-center justify-content-center">
        <Col xs={12} lg={4}>
          <img src={adas} alt="humans firts" width="100%" />
        </Col>

        <Col xs={12} lg={4}>
          <Row className="m-0 p-0 my-5 text-left">
            <Col>
              <h2 style={{ fontFamily: "Renault Bold" }}>
                Sistemas avanzados de asistencia al conductor (ADAS)
              </h2>

              <p>
                Los ADAS son sistemas inteligentes que utilizan tecnologías
                avanzadas (radares, cámaras, inteligencia artificial...) para
                ayudar a los conductores durante sus desplazamientos.
                <br />
                <br />
                Sin embargo, el 67% de los conductores no utilizan ayudas a la
                conducción a menos que se activen automáticamente (estudio SSHRC
                2019)*, Renault está trabajando para revertir esta tendencia.
                <br />
                <br />
                <span style={{ fontSize: "10px" }}>
                  * Alerta de salida de carril, asistencia de mantenimiento de
                  carril, control de crucero adaptativo inteligente, control de
                  crucero / limitador de crucero
                </span>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
