export const socialMedia = {
  facebook: "https://www.facebook.com/clamarenault/",
  instagram: "instagram.com/clama.renault/",
  youtube: "https://www.youtube.com/c/ClamaArbeiro3071/videos",
  linkedin: "https://ar.linkedin.com/company/clama-s-a-",
  tiktok: "https://www.tiktok.com/@renaultclama",
};

export const imgPath = "https://store.clama.com.ar/img/imgDefault/";
export const imagPathUsados = "https://store.clama.com.ar/img/";

export const MARCAS = {
  TOYOTA: "",
  TOYOTA_CERTIFICADO: "",
  OTRAS: "OTRAS MARCAS",
};

export const capitalize = (text) =>
  text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

export const truncate = (text, length) =>
  text && text.length > length ? `${text.substring(0, length)}` : text;
