import React, { useEffect } from "react";
import { /* Card, */ Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import bannerPrincipal from "../../assets/images/secciones/bannerPrincipal.jpg";
import bannerPrincipalMobile from "../../assets/images/secciones/bannerPrincipalMobile.jpg";
import bannerSecundario from "../../assets/images/secciones/bannerSecundario.jpg";
import bannerSecundarioMobile from "../../assets/images/secciones/bannerSecundarioMobile.jpg";
//import creditos from "../../assets/images/secciones/creditos.jpg";
import news1 from "../../assets/images/secciones/news1.jpg";
import news2 from "../../assets/images/secciones/news2.jpg";
import news3 from "../../assets/images/secciones/news3.jpg";
import sucursalesIcon from "../../assets/images/iconos/sucursales.png";
import carsIcon from "../../assets/images/iconos/cars.png";

import useModelos from "../modelos/hooks/useModelos";
import useHome from "./hooks/useHome";

import Carousel from "./Carousel";
//import Formulario from "../Formulario";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /*  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
  }; */

  const { handleTipoSeleccionado, tipoSeleccionado, modelos } = useHome();

  const { miniaturas } = useModelos();

  return (
    <>
      <Carousel />
      <Row className="m-0 p-0 mt-5 mb-3 text-center">
        <Col>
          <h4 style={{ fontFamily: "Renault Bold" }}>¿Qué estás buscando?</h4>
        </Col>
      </Row>
      <Row
        className="m-0 p-0 text-center"
        style={{ fontFamily: "Renault Bold" }}
      >
        <Col xs={12} lg={{ span: 8, offset: 2 }}>
          <Row className="m-0 p-0 ">
            <Col className="p-1" xs={6} lg={4}>
              <a
                href="https://wa.me/5491145058600?text=Hola, necesito un asesor comercial%20"
                style={{ color: "black" }}
              >
                <div className="boxHomeStyle d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon="phone"
                    style={{ color: "black", fontSize: "20px" }}
                    className="pr-1 pl-2"
                  />
                  solicitar un asesor
                </div>
              </a>
            </Col>
            <Col className="p-1" xs={6} lg={4}>
              <Link to="/sucursales" style={{ color: "black" }}>
                <div className="boxHomeStyle d-flex align-items-center justify-content-center">
                  <img
                    src={sucursalesIcon}
                    alt="sucursales"
                    width="35px"
                    className="pr-3"
                  />
                  sucursales
                </div>
              </Link>
            </Col>
            {/*  <Col className="p-1" xs={6} lg={3}>
              <a
                href="https://store.clama.com.ar/rci/"
                style={{ color: "black" }}
              >
                <div className="boxHomeStyle d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon="hand-holding"
                    style={{ color: "black", fontSize: "30px" }}
                    className="pl-1 pr-2 pb-3 "
                  />
                  Obtené tu crédito
                </div>
              </a>
            </Col> */}
            <Col className="p-1" xs={6} lg={4}>
              <Link to="/servicios" style={{ color: "black" }}>
                <div className="boxHomeStyle d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon="wrench"
                    style={{ color: "black", fontSize: "20px" }}
                    className="pr-3"
                  />
                  servicios
                </div>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-0 p-0 my-5 text-center">
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="p-0">
          <Link
            to={{
              pathname: "/modelos",
              state: { modelo: "kwid" },
            }}
          >
            <picture>
              <source media="(min-width:600px)" srcset={bannerPrincipal} />
              <img
                src={bannerPrincipalMobile}
                alt="banner principal"
                style={{ width: "100%" }}
                className="bannerHover"
              />
            </picture>
          </Link>
        </Col>
      </Row>

      <Row className="m-0 p-0 my-5 text-center">
        <Col>
          <h2 style={{ fontFamily: "Renault Bold" }}>
            Descubrí la gama Renault
          </h2>
          <p>
            Consulta nuestra seccion de <b>reserva online</b> y descubrí los
            modelos de <b>entrega inmediata</b> que tenemos para vos.
          </p>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 text-center justify-content-center">
        <Col xs={12} lg={{ span: 6, offset: 3 }}>
          <Nav>
            <Nav.Link
              className={`navButton ${
                tipoSeleccionado === "automoviles" && "navButtonActive"
              }`}
              onClick={() => handleTipoSeleccionado("automoviles")}
            >
              AUTOMÓVILES
            </Nav.Link>
            <Nav.Link
              className={`navButton ${
                tipoSeleccionado === "utilitarios" && "navButtonActive"
              }`}
              onClick={() => handleTipoSeleccionado("utilitarios")}
            >
              UTILITARIOS
            </Nav.Link>
            <Nav.Link
              className={`navButton ${
                tipoSeleccionado === "pickup" && "navButtonActive"
              }`}
              onClick={() => handleTipoSeleccionado("pickup")}
            >
              PICK-UPS
            </Nav.Link>
          </Nav>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 text-center navModelosHeight">
        <Col xs={12} lg={{ span: 8, offset: 2 }}>
          <Row className="m-0 p-0 justify-content-center">
            {miniaturas[tipoSeleccionado].map((miniatura) => (
              <Col xs={6} lg={3} key={miniatura.nombre}>
                <Link
                  to={{
                    pathname: "/modelos",
                    state: { modelo: miniatura.nombre },
                  }}
                >
                  <img
                    src={miniatura.img}
                    alt={miniatura.nombre}
                    width="100%"
                  />

                  <h5 style={{ color: "black", fontWeight: "bold" }}>
                    {miniatura.nombre === "kangooze" ? (
                      <Row>
                        <Col xs={12} className="text-center">
                          KANGOO Z.E.
                        </Col>
                        <Col
                          xs={{ span: 6, offset: 3 }}
                          className="text-center"
                          style={{
                            backgroundColor: "#0091DA",
                            borderRadius: "5px",
                            color: "white",
                            fontSize: "12px",
                          }}
                        >
                          {`eléctrico >`}
                        </Col>
                      </Row>
                    ) : miniatura.nombre === "megane" ? (
                      <Row>
                        <Col xs={12} className="text-center">
                          MEGANE
                        </Col>
                        <Col
                          xs={{ span: 6, offset: 3 }}
                          className="text-center"
                          style={{
                            backgroundColor: "#0091DA",
                            borderRadius: "5px",
                            color: "white",
                            fontSize: "12px",
                          }}
                        >
                          {`eléctrico >`}
                        </Col>
                      </Row>
                    ) : miniatura.nombre === "kwid" ? (
                      <Row>
                        <Col xs={12} className="text-center">
                          KWID
                        </Col>
                        <Col
                          xs={{ span: 6, offset: 3 }}
                          className="text-center"
                          style={{
                            backgroundColor: "#0091DA",
                            borderRadius: "5px",
                            color: "white",
                            fontSize: "12px",
                          }}
                        >
                          {`eléctrico >`}
                        </Col>
                      </Row>
                    ) : (
                      miniatura.nombre.toUpperCase()
                    )}
                  </h5>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5">
        <Col
          xs={12}
          lg={{ span: 8, offset: 2 }}
          style={{
            backgroundColor: "#f2f2f2",
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
        >
          <Row className="m-0 p-0">
            <Col xs={12} lg={6}>
              <Row className="m-0 p-0 mt-lg-5">
                <Col xs={12} lg={1} className="mb-2 text-lg-right ">
                  <img src={carsIcon} alt="cars" width="40px" />
                </Col>
                <Col xs={12} lg={11}>
                  <b style={{ fontSize: "25px", fontFamily: "Renault Bold" }}>
                    ¿Qué vehículos están disponibles hoy en día?
                  </b>
                </Col>
                <Col xs={12} className="pl-lg-5 mt-3">
                  ordene su nuevo Renault en línea de una amplia gama de
                  vehículos disponibles inmediatamente
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} className="mt-3 mt-lg-0">
              <Col xs={12}>
                {modelos.length > 0 ? (
                  modelos.map((modelo) => (
                    <Col className="my-2" key={modelo}>
                      <a
                        href="https://store.clama.com.ar/vtf"
                        style={{ color: "black" }}
                      >
                        <Row className="m-0 p-0">
                          <Col xs={6}>{modelo}</Col>
                          <Col
                            xs={6}
                            className="text-center d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: "#efdf00",
                              fontWeight: "bold",
                            }}
                          >
                            reservar
                          </Col>
                        </Row>
                      </a>
                    </Col>
                  ))
                ) : (
                  <Col className="mt-4">
                    no hay vehiculos disponibles para entrega inmediata{" "}
                  </Col>
                )}
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 text-center">
        <Col
          xs={12}
          lg={{ span: 8, offset: 2 }}
          style={{ border: "1px solid lightgray" }}
        >
          {/* <Row className="d-flex align-items-center justify-content-center">
            <Col xs={12} lg={4} className="m-0 p-0">
              <img src={creditos} alt="creditos" width="100%" className="p-3" />
            </Col>
            <Col xs={12} lg={4}>
              <h3>
                <b>¿qué financiamiento es el adecuado para vos?</b>
              </h3>
            </Col>
            <Col xs={12} lg={4} className="mt-4 mt-lg-0">
              <a href={"https://store.clama.com.ar/rci/"}>
                <Row>
                  <Col
                    xs={12}
                    lg={{ span: 6, offset: 3 }}
                    style={{
                      height: "40px",
                      color: "white",
                      backgroundColor: "black",
                      paddingTop: "7px",
                      cursor: "pointer",
                    }}
                  >
                    Hace la prueba
                  </Col>
                </Row>
              </a>
            </Col>
          </Row> */}
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 text-center">
        <Col className="mt-5">
          <h2 style={{ fontFamily: "Renault Bold" }}>NOVEDADES RENAULT</h2>
          <p>
            Nuestras tecnologías, nuestros servicios.
            <br />
            <b>CALIDAD</b>, <b>NUESTRO COMPROMISO DIARIO</b>
          </p>
        </Col>
      </Row>
      <Row className="m-5 p-0 my-5">
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={news1} alt="ventas" width="100%" />
          <p className="pt-4">
            <b>Innovaciones</b>
          </p>
          <p>
            Con Mobilize V2G, el futuro Renault 5 eléctrico se convierte en una
            fuente de energía.
          </p>
          <Link style={{ color: "black" }} to="/innovaciones">
            <p>
              ver más <span style={{ color: "#efdf00" }}>{">"}</span>
            </p>
          </Link>
        </Col>
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={news2} alt="instalaciones" width="100%" />
          <p className="pt-4">
            <b> RENAULT CARE SERVICE </b>
          </p>
          <p>
            Quien mejor que Renault para cuidar tu Renault. Tenemos planes de
            mantenimiento pensados para tu auto, para vos y tu bolsillo.
          </p>
          <Link style={{ color: "black" }} to="/rcs">
            <p>
              ver más <span style={{ color: "#efdf00" }}>{">"}</span>
            </p>
          </Link>
        </Col>
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={news3} alt="valet" width="100%" />
          <p className="pt-4">
            <b> seguridad de Renault</b>
          </p>
          <p>
            Vehículos equipados para su seguridad y la de todos los usuarios de
            la carretera
          </p>
          <Link style={{ color: "black" }} to="/humanFirst">
            <p>
              ver más <span style={{ color: "#efdf00" }}>{">"}</span>
            </p>
          </Link>
        </Col>
      </Row>
      {/*  <Row className="m-0 p-0 mb-5">
        <Col className="backgroundForm" lg={{ span: 10, offset: 1 }}>
          <Row className="m-0 p-0">
            <Col
              style={{ backgroundColor: "white" }}
              xs={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <Formulario />
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row className="m-0 p-0 mb-5 text-center">
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="p-0">
          <Link
            to={{
              pathname: "/modelos",
              state: { modelo: "stepway" },
            }}
          >
            <picture>
              <source media="(min-width:600px)" srcset={bannerSecundario} />
              <img
                src={bannerSecundarioMobile}
                alt="banner principal"
                style={{ width: "100%" }}
                className="bannerHover"
              />
            </picture>
          </Link>
        </Col>
      </Row>
      <hr />
      <Row className="m-0 p-0 mb-3">
        <Col
          className="text-center"
          onClick={() => window.scrollTo(0, 0)}
          style={{ cursor: "pointer" }}
        >
          <b>
            volver arriba <span style={{ color: "#efdf00" }}>^</span>
          </b>
        </Col>
      </Row>
    </>
  );
}
