import React from "react";

import usePlanes from "./hooks/usePlanes";
import GenericCard from "../common/GenericCard";

import planesSeccion from "../../assets/images/secciones/planesSeccion.jpg";
import planesSeccionMobile from "../../assets/images/secciones/planesSeccionMobile.jpg";

import { Row, Col, Spinner } from "react-bootstrap/";

export default function Planes() {
  const { modelos, spinner, handleButtonClick } = usePlanes();
  console.log(modelos, "modelos");

  window.scrollTo(0, 0);

  return (
    <>
      <Row className="m-0 p-0">
        <Col xs={12} className="m-0 p-0">
          <p className="textoUsados text-left">SUSCRIBITE AHORA</p>
          <picture>
            <source media="(min-width:600px)" srcset={planesSeccion} />
            <img
              src={planesSeccionMobile}
              alt="usados"
              style={{ width: "100%" }}
            />
          </picture>
        </Col>
      </Row>
      <Row className="m-0 mt-3 mb-3 p-0 justify-content-center">
        <Col
          className="text-left p-2 pl-4"
          lg={9}
          style={{
            backgroundColor: "darkgray",
            fontSize: "1.5rem",
            borderRadius: "5px",
            color: "black",
          }}
        >
          Planes de ahorro
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5 justify-content-center text-left">
        {!spinner ? (
          modelos.map(
            (modelo, i) =>
              modelo.link_modelo !== null && (
                <Col sm={5} md={5} lg={3} xl={3} className="m-1" key={"id" + i}>
                  <GenericCard
                    title={modelo.version}
                    text={modelo.tipo_plan}
                    image={modelo.foto0}
                    imageFixedPath="https://store.clama.com.ar/img/"
                    link={modelo.link_modelo}
                    from="planes"
                    price={
                      Intl.NumberFormat("es-AR", {
                        /* style: "currency", */
                        currency: "ARS",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(modelo.precio_plan) + " cuota inicial"
                    }
                    buttonBackgroundColor="black"
                    action="contratar"
                    to="/planes/ficha"
                    product={modelo}
                    handleButtonClick={handleButtonClick}
                  />
                </Col>
              )
          )
        ) : (
          <>
            <Row className="mx-0" style={{ marginTop: "2%" }}>
              <Col xs={12} className="text-center">
                Cargando...
              </Col>
              <Col
                xs={12}
                className="text-center mx-0"
                style={{ marginTop: "10px" }}
              >
                <Spinner animation="grow" variant="secondary" />
              </Col>
            </Row>
          </>
        )}
      </Row>
    </>
  );
}
