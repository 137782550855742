import { useState, useEffect, useContext } from "react";

import { Button, Col, Pagination, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import GenericCard from "../../common/GenericCard";
import WhatsappContactButton from "../../common/WhatsappContactButton";

import { accContext } from "../context/AccesoriosContext";

//import { truncate } from "../../../constants/constants";

export default function useAccesorios() {
  let history = useHistory();

  const {
    paramId,
    emptySearch,
    setEmptySearch,
    accesorios,
    POSTPERPAGE,
    setSeleccion,
    seleccion,
    setAccesorios,
    setSpinner,
    spinner,
    setSearch,
    search,
    handlePagination,
    currentPage,
    totalPages,
    PAGESONSCREEN,
    min,
    max,
    totalItems,
    handlePrev,
    handleNext,
    setTotalItems,
  } = useContext(accContext);

  const [botones, setBotones] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState(localStorage);

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
    /* localStorage.clear(); */
  };

  useEffect(() => {
    var url = `https://store.clama.com.ar/clamaapi/accesorios/modelos/`;

    fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((modelos) => {
        setBotones(modelos.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonsToRender = () => {
    //como hacer distinct de dos formas distitas
    /*  const uniqueTags = [];
    accesorios.forEach((accesorio) => {
      if (uniqueTags.indexOf(accesorio.modelo) === -1) {
        uniqueTags.push(accesorio.modelo);
      }
    }); */
    /* const uniqueTags = Array.from(
      new Set(accesorios.map(({ modelo }) => modelo))
    ); */

    return (
      <>
        <Button
          size="sm"
          variant="light"
          className="mx-1 align-text-top my-1"
          onClick={() => {
            if (paramId !== null) {
              history.push("/accesorios");
              setEmptySearch(!emptySearch);
            }
            setSeleccion("combos");
          }}
        >
          Combos
        </Button>

        {botones &&
          botones.map((item, i) => (
            <Button
              size="sm"
              variant="light"
              className="mx-1 align-text-top my-1"
              key={"id" + i}
              onClick={() => {
                if (paramId !== null) {
                  history.push("/accesorios");
                  setEmptySearch(!emptySearch);
                }
                setSeleccion(item.modelo);
              }}
            >
              {item.modelo}
            </Button>
          ))}
      </>
    );
  };

  const genericCardstoRender = () => {
    return accesorios.length > 0 ? (
      <>
        {accesorios.map(
          (accesorio, i) =>
            i <= POSTPERPAGE && (
              <Col sm={5} md={5} lg={4} xl={3} className="m-1" key={"id" + i}>
                <GenericCard
                  title={accesorio.modelo}
                  text={accesorio.anio}
                  image={accesorio.foto0}
                  imageFixedPath="https://store.clama.com.ar/img/"
                  price={Intl.NumberFormat("es-AR", {
                    /* style: "currency", */
                    currency: "ARS",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(accesorio.precio)}
                  buttonBackgroundColor="black"
                  action="comprar"
                  to="/tarjetas"
                  product={{
                    id: accesorio.id,
                    modelo: accesorio.modelo,
                    motor: accesorio.motor,
                    foto0: accesorio.foto0,
                    foto1: accesorio.foto1,
                    foto2: accesorio.foto2,
                    foto3: accesorio.foto3,
                    foto4: accesorio.foto4,
                    foto5: accesorio.foto5,
                    foto6: accesorio.foto6,
                    foto7: accesorio.foto7,
                    foto8: accesorio.foto8,
                    foto9: accesorio.foto9,
                    foto10: accesorio.foto10,
                    foto11: accesorio.foto11,
                    precio: accesorio.precio,
                    moneda: "$",
                    origen: "postventa",
                  }}
                  handleButtonClick={handleButtonClick}
                  //--- atributos de autos usados
                  from="accesorios"
                  link="/accesorios/accesoriosFotos"
                  whatsappButton={
                    <WhatsappContactButton
                      origen="accesorios"
                      modelo={accesorio}
                    />
                  }
                  compatible={accesorio.descripcion}
                />
              </Col>
            )
        )}
      </>
    ) : (
      <Col className="text-center my-4" xs={12} md={{ span: 4 }}>
        <h4>No hay modelos disponibles para su seleccion</h4>
      </Col>
    );
  };

  const handleChangeFilter = (event) => {
    setSearch(event.target.value);
  };

  const getPagination = () => {
    return (
      totalItems > POSTPERPAGE && (
        <Row className="m-0 p-0 mb-4">
          <Col>
            <Pagination className="justify-content-center">
              <Pagination.First onClick={() => handlePagination(1)} />
              <Pagination.Prev onClick={() => handlePrev()} />

              {[...Array(totalPages)].map(
                (_, i) =>
                  currentPage - PAGESONSCREEN < i + 1 &&
                  currentPage + PAGESONSCREEN > i + 1 && (
                    <Pagination.Item
                      key={i}
                      active={i + 1 === currentPage && true}
                      onClick={() => handlePagination(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  )
              )}

              <Pagination.Next onClick={() => handleNext()} />
              <Pagination.Last onClick={() => handlePagination(totalPages)} />
            </Pagination>
          </Col>
        </Row>
      )
    );
  };

  const handleSearch = () => {
    if (search === "") {
      setEmptySearch(!emptySearch);
    }

    setSpinner(true);
    let url = `https://store.clama.com.ar/clamaapi/accesorios/search/${search}/modelo/${seleccion}/${min}/${max}`;

    fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((response) => {
        setAccesorios(response.data);
        setTotalItems(response.total[0].total);
        setSpinner(false);
      });
  };

  return {
    spinner,
    genericCardstoRender,
    buttonsToRender,
    handleChangeFilter,
    search,
    getPagination,
    seleccion,
    handleSearch,
    items,
  };
}
