import React from "react";
import { Col, Row } from "react-bootstrap";

import insignia from "../../../assets/images/secciones/rcs/svg/insignia.svg";
import location from "../../../assets/images/secciones/rcs/svg/location.svg";
import mano from "../../../assets/images/secciones/rcs/svg/mano.svg";
import pc from "../../../assets/images/secciones/rcs/svg/pc.svg";
import rueda from "../../../assets/images/secciones/rcs/svg/rueda.svg";
import sol from "../../../assets/images/secciones/rcs/svg/sol.svg";
import totem from "../../../assets/images/secciones/rcs/svg/totem.svg";
import trofeo from "../../../assets/images/secciones/rcs/svg/trofeo.svg";

import amortiguador from "../../../assets/images/secciones/rcs/amortiguador.jpg";
import bateria from "../../../assets/images/secciones/rcs/bateria.jpg";
import bombillas from "../../../assets/images/secciones/rcs/bombillas.jpg";
import discos from "../../../assets/images/secciones/rcs/discos.jpg";
import embrague from "../../../assets/images/secciones/rcs/embrague.jpg";
import limpiaparabrisas from "../../../assets/images/secciones/rcs/limpiaparabrisas.jpg";
import pastillas from "../../../assets/images/secciones/rcs/pastillas.jpg";
import neumaticos from "../../../assets/images/secciones/rcs/neumaticos.jpg";

export default function useNews() {
  const renderBoxes = () => {
    const boxes = [
      {
        title: "SEGURIDAD",
        description: "Contamos con profesionales especializados.",
        image: insignia,
      },
      {
        title: "PLAN DE MANTENIMIENTO PERSONALIZADO",
        description: "",
        image: mano,
      },
      {
        title: "CONFIANZA Y DURABILIDAD",
        description: "Todos nuestros repuestos son originales.",
        image: trofeo,
      },
      {
        title: "GARANTÍA DE TU VEHÍCULO",
        description: "",
        image: rueda,
      },
      {
        title: "RENAULT ASSISTANCE",
        description:
          "Programa de asistencia las 24 hs y los 365 días del año en el país y países limítrofes.",
        image: pc,
      },
      {
        title: "PRECIOS TRANSPARENTES",
        description: "",
        image: sol,
      },
      {
        title: "RED DE SERVICIO MÁS AMPLIA DEL PAÍS",
        description: "",
        image: location,
      },
      {
        title: "RENAULT MINUTO",
        description: "Red de mayor amplitud de horarios y sin turno.",
        image: totem,
      },
    ];

    return boxes.map((box, index) => {
      return (
        <Col xs={12} lg={3}>
          <Col
            className="m-0 p-4"
            key={index}
            style={{ border: "1px solid lightgray", height: "90%" }}
          >
            <Row className="m-0 p-0">
              <Col xs={3} lg={12} className="m-0 p-0">
                <img src={box.image} alt={box.title} />
              </Col>
              <Col
                xs={9}
                lg={12}
                className="d-flex align-items-center justify-content-center pt-lg-4"
              >
                <p>
                  <b>{box.title}</b>
                  <p>{box.description}</p>
                </p>
              </Col>
            </Row>
          </Col>
        </Col>
      );
    });
  };

  const renderServiciosSugeridos = () => {
    const servicios = [
      {
        title: "Lamparas",
        image: bombillas,
        service: "10 a 100 mil kilómetros",
      },
      {
        title: "Escobillas",
        image: limpiaparabrisas,
        service: "10 a 100 mil kilómetros",
      },
      {
        title: "Pastillas de freno",
        image: pastillas,
        service: "30, 60 y 90 mil kilómetros",
      },
      {
        title: "Batería",
        image: bateria,
        service: " 50 y 100 mil kilómetros",
      },
      {
        title: "Amortiguadores",
        image: amortiguador,
        service: "50 y 90 mil kilómetros",
      },
      {
        title: "Embrague",
        image: embrague,
        service: "100 mil kilómetros",
      },
      {
        title: "Neumáticos",
        image: neumaticos,
        service: "50 y 90 mil kilómetros",
      },
      {
        title: "Discos de freno",
        image: discos,
        service: "90 mil kilómetros",
      },
    ];

    return servicios.map((servicio, index) => {
      return (
        <Col xs={12} lg={3}>
          <Col className="m-0 p-4" key={index} style={{ height: "90%" }}>
            <Row className="m-0 p-0">
              <Col xs={3} lg={12} className="m-0 p-0">
                <img src={servicio.image} alt={servicio.title} width="100%" />
              </Col>
              <Col
                xs={9}
                lg={12}
                className="d-flex align-items-center justify-content-center pt-lg-4"
              >
                <p>
                  <b>{servicio.title}</b>
                  <p>{servicio.service}</p>
                </p>
              </Col>
            </Row>
          </Col>
        </Col>
      );
    });
  };

  return { renderBoxes, renderServiciosSugeridos };
}
