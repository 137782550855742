import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useModelos from "./hooks/useModelos";
import { Col, Image, Modal, Nav, Row } from "react-bootstrap";

import "./styles/styles.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
/* import Slider from "../home/slider/Slider"; */

export default function Modelos() {
  const location = useLocation();

  const { modelos, randomMiniatura, miniaturas } = useModelos();

  const autoRandom = miniaturas.automoviles[randomMiniatura()[0]];
  const utilitarioRandom = miniaturas.utilitarios[randomMiniatura()[1]];
  const pickupRandom = miniaturas.pickup[randomMiniatura()[2]];

  const modelo = location.state ? location.state.modelo : "koleos";

  const [show, setShow] = useState(false);

  const [img, setImg] = useState(null);

  function handleShow() {
    setShow(true);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modelo]);

  return (
    <>
      <Nav className="flex-column flex-lg-row py-3 align-items-lg-center">
        <Nav.Link>
          <b
            style={{
              fontWeight: "bold",
              paddingBottom: "8px",
              borderBottom: "2px solid #efdf00",
              fontFamily: "Renault Bold",
              color: "black",
            }}
          >
            {modelos[modelo].nombre === "kangooze"
              ? "KANGOO Z.E."
              : modelos[modelo].nombre.toLocaleUpperCase()}
          </b>
        </Nav.Link>
        <Nav.Link className="navButton" href="https://store.clama.com.ar/vtf/">
          VER DISPONIBILIDAD
        </Nav.Link>
        <Nav.Link
          className="navButton"
          href={modelos[modelo].catalogo}
          target="_blank"
          rel="noreferrer"
        >
          DESCARGAR CATÁLOGO
        </Nav.Link>
      </Nav>
      <Row className="m-0 p-0">
        <Col className="m-0 p-0">
          <picture>
            <img
              src={modelos[modelo].banner}
              alt="banner"
              style={{ width: "100%", height: "50vh", objectFit: "cover" }}
            />
          </picture>
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="py-3">
          <b className="title">
            RENAULT{" "}
            {modelos[modelo].nombre === "kangooze"
              ? "KANGOO Z.E."
              : modelos[modelo].nombre.toLocaleUpperCase()}
          </b>

          <div className="pb-3">{modelos[modelo].slogan}</div>

          <a
            className="mt-3"
            href="https://wa.me/5491145058600?text=Hola, necesito un asesor comercial%20"
            style={{ color: "black" }}
          >
            <b>
              solicitar un asesor
              <span style={{ color: "#efdf00" }}>{">"}</span>
            </b>
          </a>
        </Col>
      </Row>
      <Row className="m-0 p-0 mt-5">
        <Col
          lg={{ span: 2, offset: 3 }}
          onClick={() => {
            setImg(modelos[modelo].foto1);
            handleShow();
          }}
          style={{ cursor: "pointer" }}
        >
          <img src={modelos[modelo].foto1} width="100%" alt="foto modelo" />
        </Col>
        <Col lg={4}>
          <Col xs={12} className="preFix">
            {modelos[modelo].preFix}
          </Col>
          <Col xs={12} className="title">
            {modelos[modelo].header}
            <br />
          </Col>
          <Col xs={12} className="pb-4">
            {modelos[modelo].subHeader}
          </Col>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        <Col
          lg={{ span: 3, offset: 2 }}
          onClick={() => {
            setImg(modelos[modelo].foto2);
            handleShow();
          }}
          style={{ cursor: "pointer" }}
        >
          <img src={modelos[modelo].foto2} width="100%" alt="foto modelo" />
        </Col>
        <Col lg={2}>
          <Row>
            <Col
              xs={{ span: 6, offset: 3 }}
              lg={{ span: 8, offset: 2 }}
              className="text-center my-3 p-3 actionButtonBackground"
            >
              <a
                href="https://store.clama.com.ar/vtf/"
                style={{ color: "white" }}
                className="actionButton"
              >
                ver disponibilidad
              </a>
            </Col>
          </Row>
          <Row>
            <Col
              onClick={() => {
                setImg(modelos[modelo].foto3);
                handleShow();
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={modelos[modelo].foto3} width="100%" alt="foto modelo" />
            </Col>
          </Row>
        </Col>
        <Col
          lg={3}
          onClick={() => {
            setImg(modelos[modelo].foto4);
            handleShow();
          }}
          style={{ cursor: "pointer" }}
        >
          <img src={modelos[modelo].foto4} width="100%" alt="foto modelo" />
        </Col>
      </Row>
      <Row className="m-0 p-0 mt-5">
        <Col xs={12} lg={{ span: 4, offset: 4 }} className="text-center mt-5">
          <Col xs={12} className="preFix">
            {modelos[modelo].preFixEquipo}
          </Col>
          <Col xs={12} className="title">
            {modelos[modelo].headerEquipo}
            <br />
          </Col>
          <Col xs={12} className="pb-4">
            {modelos[modelo].subHeaderEquipo}
          </Col>
        </Col>
      </Row>
      <Row className="m-5 p-0 my-5">
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={modelos[modelo].featureImg1} alt="features" width="100%" />
          <p className="pt-4">
            <b>{modelos[modelo].featureHeader1}</b>
          </p>
          <p>{modelos[modelo].featureDescription1}</p>
        </Col>
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={modelos[modelo].featureImg2} alt="features" width="100%" />
          <p className="pt-4">
            <b>{modelos[modelo].featureHeader2}</b>
          </p>
          <p>{modelos[modelo].featureDescription2}</p>
        </Col>
        <Col xs={12} lg={4} className="p-1 my-4">
          <img src={modelos[modelo].featureImg3} alt="features" width="100%" />
          <p className="pt-4">
            <b>{modelos[modelo].featureHeader3}</b>
          </p>
          <p>{modelos[modelo].featureDescription3}</p>
        </Col>
      </Row>
      <Row className="m-0 p-0 mt-5">
        <Col xs={12} lg={{ span: 4, offset: 4 }} className="text-center mt-5">
          <Col xs={12} className="preFix">
            descubrí
          </Col>
          <Col xs={12} className="title">
            {modelos[modelo].nombre === "kangooze"
              ? "KANGOO Z.E."
              : modelos[modelo].nombre.toLocaleUpperCase()}
            <br />
          </Col>
          <Col xs={12} className="pb-4">
            {modelos[modelo].slogan}
          </Col>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        <Col
          xs={12}
          lg={{ span: 8, offset: 1 }}
          className="p-0 py-4"
          style={{ backgroundColor: "lightgray" }}
        >
          <picture>
            <img
              src={modelos[modelo].modelo}
              alt="modelo"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </picture>
        </Col>
        <Col
          xs={12}
          lg={2}
          className="d-flex align-items-center justify-content-center text-center"
          style={{ backgroundColor: "lightgray" }}
        >
          <Row className="m-0 p-0">
            <a
              href="https://wa.me/5491145058600?text=Hola, necesito un asesor comercial%20"
              style={{ color: "black", width: "100%" }}
            >
              <Col xs={12} className="styledButton">
                solicitar un asesor
              </Col>
            </a>
            <a
              href="https://store.clama.com.ar/vtf/"
              style={{ color: "black", width: "100%" }}
            >
              <Col xs={12} className="styledButton">
                ver disponibilidad
              </Col>
            </a>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 mt-5">
        <Col xs={12} lg={{ span: 4, offset: 4 }} className="text-center mt-5">
          <Col xs={12} className="title">
            Ver otros modelos
            <br />
          </Col>
        </Col>
      </Row>
      <Row
        className="m-5 p-0 mb-5 text-center"
        style={{ fontFamily: "Renault Bold" }}
      >
        <Col xs={12} lg={{ span: 2, offset: 3 }} className="p-1">
          <Link
            style={{ color: "black" }}
            to={{
              pathname: "/modelos",
              state: {
                modelo: autoRandom.nombre,
              },
            }}
          >
            <img src={autoRandom.img} alt="miniatura" />
            RENAULT {autoRandom.nombre.toUpperCase()}
          </Link>
        </Col>
        <Col xs={12} lg={2} className="p-1 ">
          <Link
            style={{ color: "black" }}
            to={{
              pathname: "/modelos",
              state: {
                modelo: utilitarioRandom.nombre,
              },
            }}
          >
            <img src={utilitarioRandom.img} alt="miniatura" />
            RENAULT {utilitarioRandom.nombre.toUpperCase()}
          </Link>
        </Col>
        <Col xs={12} lg={2} className="p-1">
          <Link
            style={{ color: "black" }}
            to={{
              pathname: "/modelos",
              state: {
                modelo: pickupRandom.nombre,
              },
            }}
          >
            <img src={pickupRandom.img} alt="miniatura" />
            RENAULT {pickupRandom.nombre.toUpperCase()}
          </Link>
        </Col>
      </Row>
      <hr />
      <Row className="m-0 p-0 mb-3">
        <Col
          className="text-center"
          onClick={() => window.scrollTo(0, 0)}
          style={{ cursor: "pointer" }}
        >
          <b>
            volver arriba <span style={{ color: "#efdf00" }}>^</span>
          </b>
        </Col>
      </Row>
      <Modal id="modalSize" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton style={{ height: "50px" }}>
          <Modal.Title>
            <div
              style={{
                fontFamily: "Renault Bold",
                fontSize: "30px",
                marginTop: "-15px",
              }}
            >
              {modelos[modelo].nombre === "kangooze"
                ? "KANGOO Z.E."
                : modelos[modelo].nombre.toLocaleUpperCase()}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0 p-0" style={{ backgroundColor: "black" }}>
          <Image src={img} style={{ width: "100%" }} infiniteLoop={false} />
        </Modal.Body>
      </Modal>
    </>
  );
}
